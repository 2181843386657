import ovp from '@accedo/vdkweb-ovp-client-accedo';
// import vdkFetch from '@accedo/vdkweb-fetch';
import dayjs from 'dayjs';

import {
  getProtocolRelativePathUrl,
  getQueryStringParameters
} from '#/utils/url';
import { fetchWithTimeout } from '#/utils/fetch';
import config from '#/config';
import { getOVPConfig } from '#/providers/shared/control/config';
import { createOptsObject } from './utils';

const errorHandler = error => console.error(error);

const channelInfoCache = {};
const CACHE_SIZE = 100 * 60 * 1000; // 5 mins
let segmentationStoredValue;

const collectionPath = 'collection/asset';

const { accedoOvpUrl } = config.app;

/**
 * @module providers/ovp
 * @description
 * Provider OVP implementation for Accedo OVP
 */

/**
 * Override of the default function to avoid CORS issues.
 * Invokes the REST service using the supplied settings and parameters.
 * @param {String} path The base URL to invoke.
 * @param {String} httpMethod The HTTP method to use.
 * @param {Object.<String, String>} pathParams A map of path parameters and their values.
 * @param {Object.<String, Object>} queryParams A map of query parameters and their values.
 * @param {Object.<String, Object>} headerParams A map of header parameters and their values.
 * @param {Object.<String, Object>} formParams A map of form parameters and their values.
 * @param {Object} bodyParam The value to pass as the request body.
 * @param {Array.<String>} authNames An array of authentication type names.
 * @param {Array.<String>} contentTypes An array of request MIME types.
 * @param {Array.<String>} accepts An array of acceptable response MIME types.
 * @param {string} [segmentationValue] optional segment to get a different config
 * constructor for a complex type.
 * @returns {Promise} A {@link https://www.promisejs.org/|Promise} object.
 */
ovp.ApiClient.prototype.call = function call(
  path,
  httpMethod,
  pathParams,
  queryParams,
  headerParams,
  formParams,
  bodyParam,
  authNames,
  contentTypes,
  accepts
) {
  return ovp.ApiClient.prototype
    .buildUrl(
      path,
      pathParams,
      ovp.ApiClient.prototype.normalizeParams(queryParams)
    )
    .then(url => {
      const headers = {
        ...{},
        ...ovp.ApiClient.defaultHeaders,
        ...ovp.ApiClient.prototype.normalizeParams(headerParams)
      };
      const contentType = ovp.ApiClient.prototype.jsonPreferredMime(
        contentTypes
      );
      headers['Content-Type'] = contentType;
      const acceptType = ovp.ApiClient.prototype.jsonPreferredMime(accepts);
      if (acceptType) {
        headers.Accept = acceptType;
      }

      if (contentType === 'multipart/form-data') {
        throw new Error('Not implemented - contentType not supported');
      }

      let body;
      if (httpMethod !== 'GET' && httpMethod !== 'HEAD' && bodyParam) {
        body = JSON.stringify(bodyParam);
      }

      const options = {
        method: httpMethod,
        headers
      };

      if (body) {
        options.body = body;
      }

      return fetchWithTimeout(url, errorHandler);
    });
};

const getBaseUrl = async () => {
  // OVP API url is built based on the CMS Config value if any or the value from the static config if not provided
  const ovpConfiguration = await getOVPConfig(segmentationStoredValue);
  return ovpConfiguration?.url || accedoOvpUrl;
};

ovp.ApiClient.prototype.getBaseUrl = async () => {
  // OVP API url is built based on the CMS Config value if any or the value from the static config if not provided
  return getBaseUrl();
};

// Copied from @accedo/vdkweb-ovp-client-accedo an updated to handle Asyncronous getBaseUrl to allow remote config baseUrl
ovp.ApiClient.prototype.buildUrl = async function buildUrl(
  path,
  pathParams,
  queryParams
) {
  if (!path.match(/^\//)) {
    path = `/${path}`;
  }
  const baseUrl = await this.getBaseUrl();
  let url = baseUrl;
  url = url.replace(/\/*$/, '') + path;
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const _this = this;
  url = url.replace(/\{([\w-]+)\}/g, (fullMatch, key) => {
    let value;
    // eslint-disable-next-line no-prototype-builtins
    if (pathParams.hasOwnProperty(key)) {
      value = _this.paramToString(pathParams[key]);
    } else {
      value = fullMatch;
    }
    return encodeURIComponent(value);
  });

  if (Object.keys(queryParams).length) {
    url += '?';
    const queryParamsKeys = Object.keys(queryParams);
    queryParamsKeys.forEach(key => {
      url += `${encodeURIComponent(key)}=${encodeURIComponent(
        queryParams[key]
      )}&`;
    });
  }
  return url;
};

const movieDataParser = entry => ({
  ...entry,
  displayText: entry.title
});

/**
 * gets Movie from a category
 * @param {string} [params.category] category string
 * @param {string} [params.sortBy] sort para, string
 * @param {number} [params.pageSize] number of items to return
 * @param {string} [paramssegmentationValue] optional segment to get a different config
 * @returns {any} movie
 */
const getMovieData = async ({
  category = '',
  sortBy = '',
  pageSize,
  segmentationValue
} = {}) => {
  segmentationStoredValue = segmentationValue;
  const apiInstance = new ovp.MovieApi();

  const opts = await createOptsObject({
    pageSize,
    pageNumber: 1,
    sortBy,
    segmentationValue
  });

  try {
    const data = await (category
      ? apiInstance.getMoviesByCategory(category, opts)
      : apiInstance.getAllMovies(opts));

    return data.entries.map(entry => {
      return {
        ...entry,
        displayText: entry.title
      };
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

/**
 * gets Movie from a provider identifier
 * @param {string} id movie identifier
 * @param {string} [segmentationValue] optional segment to get a different config
 * @returns {any} movie
 */
const getMovieById = (id, segmentationValue) => {
  if (!id) {
    return Promise.resolve(null);
  }
  segmentationStoredValue = segmentationValue;
  const apiInstance = new ovp.MovieApi();

  return apiInstance.getMovieById(id).catch(errorHandler);
};

/**
 * It returns a list of Movies associated with the provider category
 *
 * @param {object} params param object
 * @param {string} params.category param object
 * @param {string} [params.sortBy] param object
 * @param {string} [params.segmentationValue] optional segment to get a different value based on config
 *
 * @returns {Promise<Movies[]>} the list of movies
 */
const getMoviesByCategory = async ({ category, sortBy, segmentationValue }) => {
  segmentationStoredValue = segmentationValue;
  // Due to category OVP limitation we can't paginate so we request more than default pageSize
  return category
    ? getMovieData({ category, sortBy, pageSize: 50, segmentationValue })
    : null;
};

const getMovieDataById = (id, segmentationValue) =>
  getMovieById(id, segmentationValue).then(movieDataParser);

/**
 * Gets the TV Shows for a category or general
 * @param {any} options parameter object
 * @param {string} [options.category] category
 * @param {number} [options.pageSize] category
 * @param {string} [options.segmentationValue] segmentationValue to filter
 * @returns {Promise<any>} TV Shows
 */
const getTvShowData = async ({
  category = '',
  pageSize,
  segmentationValue
} = {}) => {
  segmentationStoredValue = segmentationValue;
  const apiInstance = new ovp.TVShowApi();

  const opts = await createOptsObject({
    pageSize,
    pageNumber: 1,
    segmentationValue
  });

  try {
    const data = await (category
      ? apiInstance.getTvShowsByCategory(category, opts)
      : apiInstance.getAllTvShows(opts));

    return data.entries.map(entry => {
      return {
        ...entry,
        displayText: entry.title,
        type: 'tvshow'
      };
    });
  } catch (error) {
    console.error(error);
  }
};

/**
 * Gets all the TV Shows
 * @param {*} options parameter object
 * @param {*} [options.segmentationValue] segmentationValue to filter
 * @returns {Promise<any>} TV Shows
 */
const getTvShows = async (options = {}) => {
  segmentationStoredValue = options.segmentationValue;
  const opts = {
    pageSize: 50,
    ...options
  };
  const apiInstance = new ovp.TVShowApi();
  return apiInstance.getAllTvShows(opts);
};

/**
 * Gets all the movies
 * @param {*} options parameter object
 * @param {*} [options.segmentationValue] segmentationValue to filter
 * @returns {Promise<any>} Movies
 */
const getMovies = async (options = {}) => {
  segmentationStoredValue = options.segmentationValue;
  const opts = {
    pageSize: 50,
    ...options
  };
  const apiInstance = new ovp.MovieApi();
  return apiInstance.getAllMovies(opts);
};

/**
 * Search for Movies
 * @param {Object} params parameter object
 * @param {String} params.keyword Keyword to search for Movies
 * @param {Number} [params.amount] Amount of items to return
 * @param {string} [params.segmentationValue] optional segment to get a different config
 * @return {Array<Object>} Results
 */
const searchMovies = async ({ keyword, amount, segmentationValue } = {}) => {
  if (!keyword) {
    return Promise.resolve(null);
  }
  segmentationStoredValue = segmentationValue;

  const opts = await createOptsObject({
    pageSize: amount,
    sortBy: 'title',
    segmentationValue
  });
  const apiInstance = new ovp.SearchApi();
  return apiInstance.searchMovies(keyword, opts).catch(errorHandler);
};

/**
 * Search for Shows
 * @param {object} params params
 * @param {String} params.keyword Keyword to search for Shows
 * @param {Number} params.amount Amount of items to return
 * @param {string} [params.segmentationValue] optional segment to get a different config
 * @return {Array<Object>} Results
 */
const searchShows = async ({ keyword, amount, segmentationValue }) => {
  if (!keyword) {
    return Promise.resolve(null);
  }
  segmentationStoredValue = segmentationValue;
  const opts = await createOptsObject({
    sortBy: 'title',
    pageSize: amount,
    segmentationValue
  });

  const params = new URLSearchParams();
  Object.keys(opts).forEach(key => params.append(key, opts[key]));
  const baseUrl = await getBaseUrl();
  return fetchWithTimeout(
    `${baseUrl}/search/tvshow/${keyword}?${params.toString()}`,
    errorHandler
  );
};

/**
 * Get a TV Show
 * @todo Review the TV Show API since it doesn't return
 * all the information provided by APIs. https://github.com/Accedo-Global-Solutions/vdkweb-ovp-client-accedo/tree/296088a7709a953c1a7aea345c38b316332e339c/docs/TVShowApi.md#getTvShowById
 * @param {string} id TV Show ID
 * @param {string} [segmentationValue] the value for the segmentation to filter/use for any possible filtering
 * @returns {Object} TV Show
 */
const getTvShowById = async (id, segmentationValue) => {
  segmentationStoredValue = segmentationValue;
  if (!id) {
    return Promise.resolve(null);
  }
  const baseUrl = await getBaseUrl();
  return fetchWithTimeout(`${baseUrl}/tvshow/${id}`, errorHandler);
};

/**
 * Get episodes from a season
 * @param {String} id Season ID
 * @param {string} [segmentationValue] optional segment to get a different config
 * @returns {Array<Object>} Episodes
 */
const getTvSeasonEpisodesById = (id, segmentationValue) => {
  if (!id) {
    return Promise.resolve(null);
  }
  segmentationStoredValue = segmentationValue;
  const apiInstance = new ovp.EpisodeApi();

  return apiInstance
    .getTvSeasonEpisodes(id, { pageSize: 1000 })
    .catch(errorHandler);
};

/**
 * Get episodes from a tv show
 * @param {String} id TV Show ID
 * @returns {Array<Object>} Episodes
 */
const getTvShowEpisodesById = id => {
  if (!id) {
    return Promise.resolve(null);
  }
  const apiInstance = new ovp.EpisodeApi();

  return apiInstance
    .getTvShowEpisodes(id, { pageSize: 1000 })
    .catch(errorHandler);
};

/**
 * Get seasons from a tv show
 * @param {String} id TV Show ID
 * @param {string} [segmentationValue] optional segment to get a different config
 * @returns {Array<Object>} Seasons
 */
const getTvShowSeasonsById = (id, segmentationValue) => {
  segmentationStoredValue = segmentationValue;
  if (!id) {
    return Promise.resolve(null);
  }
  const apiInstance = new ovp.TVSeasonApi(segmentationStoredValue);

  return apiInstance
    .getTvShowSeasons(id, { sortBy: 'tvSeasonNumber' })
    .catch(errorHandler);
};

/**
 * Get an episode
 * @param {String} id Episode ID
 * @param {string} [segmentationValue] optional segment to get a different config
 * @returns {Object} Episode
 */
const getEpisodeById = (id, segmentationValue) => {
  if (!id) {
    return Promise.resolve(null);
  }
  segmentationStoredValue = segmentationValue;
  const apiInstance = new ovp.EpisodeApi();

  return apiInstance.getEpisodeById(id).catch(errorHandler);
};

/**
 * Get all the TV Shows from a category
 * @param {object} params object parameter
 * @param {string} params.category category string
 * @param {string} [params.segmentationValue]  segmentationValue
 * @returns {Promise<any[]>} TVShows
 */
const getTvShowsByCategory = async ({ category, segmentationValue } = {}) => {
  // Due to category OVP limitation we can't paginate so we request more than default pageSize
  return category
    ? getTvShowData({ category, pageSize: 50, segmentationValue })
    : null;
};

/**
 * Get Movie categories, all the categories associated with movies
 * @param {string} [segmentationValue] optional segment to get a different config
 * @returns {any} categories
 */
const getMovieCategories = async segmentationValue => {
  segmentationStoredValue = segmentationValue;
  const apiInstance = new ovp.CategoryApi();

  const opts = await createOptsObject({
    pageNumber: 1,
    segmentationValue
  });

  try {
    const movieCategoryId = 'movies';
    const data = await apiInstance.getCategoryById(movieCategoryId, opts);

    return data.categories
      .map(entry => {
        return {
          ...entry,
          displayText: entry.title
        };
      })
      .slice(0, opts.pageSize);
  } catch (error) {
    console.error(error);
  }
};

const signIn = credentials => {
  return new ovp.AuthApi().authenticate(
    credentials.email,
    credentials.password
  );
};

const signOut = token => {
  return new ovp.AuthApi().invalidateToken(token);
};

const validateToken = (token, userId) => {
  return new ovp.AuthApi().validateToken(token, userId);
};

let channelDataCache;

/**
 * Returns all the data associated with the channels from the OVP
 * @param {string} [segmentationValue] optional segment to get a different config
 * @returns {Promise<any>} the channels info
 */
const getChannelData = segmentationValue => {
  if (channelDataCache && segmentationStoredValue === segmentationValue) {
    return Promise.resolve(channelDataCache);
  }
  segmentationStoredValue = segmentationValue;

  const apiInstance = new ovp.ChannelApi();

  return apiInstance
    .getAllChannels()
    .then(channelData => {
      channelDataCache = channelData;
      return channelData;
    })
    .catch(error => {
      console.error(error);
    });
};

/**
 * Returns a set of listings for the specified time and combination of count+offset
 *
 * @param {Object} params Params object
 * @param {number} params.startTime timestamp value for the initial time to fetch the data for the listings
 * @param {number} params.endTime timestamp value for the final time to fetch the data for the listings
 * @param {number} params.count the number of channels to return the data from
 * @param {number} params.offset the initial position of the channel to start returning data from
 * @param {string} [params.segmentationValue] optional segment to get a different value based on config
 *
 * @returns {Promise<any>} the listings for the specified params
 */
const getTvListings = async ({
  startTime,
  endTime,
  count,
  offset = 0,
  segmentationValue
}) => {
  segmentationStoredValue = segmentationValue;
  const apiInstance = new ovp.TVListingApi();
  const params = await createOptsObject({
    pageSize: count,
    pageNumber: 1 + Math.max(Math.round(offset / count), 0),
    segmentationValue
  });

  const channels = await getChannelData(segmentationValue);

  const data = await apiInstance.getTvListing(startTime, endTime, params);
  return {
    ...data,
    entries: data.entries.map(entry => {
      const channelData = channels.entries.find(
        channel => channel.id === entry.channelId
      );
      if (channelData && channelData.images && channelData.images.length) {
        channelData.images.forEach(image => {
          image.url = getProtocolRelativePathUrl(image.url);
        });
      }

      return {
        ...(channelData || {}),
        ...entry,
        programs: entry.programs
          .filter(
            p =>
              dayjs(p.startTime).day() === dayjs(startTime).day() ||
              (p.endTime <= endTime && p.startTime < endTime)
          )
          .map(p => {
            return {
              ...p,
              title: p.title,
              videoUrl:
                '//d3qnhznroa8hr5.cloudfront.net/videos/bbb_multiaudio.mp4'
            };
          })
      };
    })
  };
};

/**
 * Get TVListing from a channel, doing a general tvlisting request and caching the value for all
 * the channels and then returning only the channel provided by param.
 * Challenge: define time windows to use the cached values and when to refresh the data
 *
 * @param {Object} params Params object
 * @param {number} params.channel Channel identifier
 * @param {number} params.startTime timestamp value for the initial time to fetch the data for the listings
 * @param {string} [params.segmentationValue] optional segment to get a different value based on config
 *
 *
 * @returns {Promise<any>} tvlistings
 */
const getCachedTvListings = async ({
  channel,
  startTime,
  segmentationValue
}) => {
  segmentationStoredValue = segmentationValue;
  if (
    !channelInfoCache?.promise ||
    startTime > channelInfoCache?.maxStartTime
  ) {
    const endTime = startTime + CACHE_SIZE;
    channelInfoCache.promise = getTvListings({
      startTime,
      endTime,
      channel,
      count: 100,
      segmentationValue
    });
    channelInfoCache.maxStartTime = endTime;
  }
  const tvlistingsPromise = channelInfoCache?.promise;
  const tvlistings = await tvlistingsPromise.then(response => response);
  const filteredListings = tvlistings.entries.filter(
    entry => entry.channelId === channel
  );
  return filteredListings?.[0] || {};
};

/**
 * Returns the program data associated with a channel for a certain time
 * @param {Object} params params object
 * @param {string} params.channel channel identifier
 * @param {number} params.startTime The start time to fetch information (unix timestamp in ms)
 * @param {string} [params.segmentationValue] optional segment to get a different value based on config
 * @returns {Promise<any>} program data
 */
const getChannelTvListings = async ({
  channel,
  startTime,
  segmentationValue
}) => {
  const tvListings = await getCachedTvListings({
    channel,
    startTime,
    segmentationValue
  });
  return (
    tvListings.programs?.find(program => {
      const currentTime = Date.now();
      return currentTime > program.startTime && currentTime <= program.endTime;
    }) || {}
  );
};

/**
 *
 * @param {object} params parameters object
 * @param {string} params.query query to parse against the OVP API
 * @param {number} params.itemsPerPage number of items to request
 * @param {number} params.pageNumber page to request
 * @param {string} params.sortBy sort para string value
 * @param {string} [params.segmentationValue] optional segment to get a different config
 * @returns {any[]} data to display
 */
const getItemsByQuery = async ({
  query,
  itemsPerPage,
  pageNumber,
  sortBy,
  segmentationValue
}) => {
  if (!query) {
    return null;
  }

  const pageSize = itemsPerPage;
  const optionsRequest = await createOptsObject({
    sortBy,
    pageSize,
    pageNumber,
    segmentationValue
  });

  const queryParams = getQueryStringParameters(query);

  const searchParams = new URLSearchParams();
  Object.keys(optionsRequest).forEach(key => {
    if (!queryParams || !queryParams[key]) {
      // query could have params already, we do not want duplicate them
      optionsRequest[key] && searchParams.append(key, optionsRequest[key]);
    }
  });
  const hasParams = searchParams.toString().length > 0;
  const baseUrl = await getBaseUrl();
  const fetchUrl = hasParams
    ? `${baseUrl}${query}?${searchParams.toString()}`
    : `${baseUrl}${query}`;

  let items = [];
  const data = await fetchWithTimeout(fetchUrl, errorHandler);
  if (!data || data.error) {
    // when error
    return { error: data.error || 'Error fetching data' };
  }
  items = data?.entries;

  return { items, total: data?.totalCount };
};

/**
 * Returns a collection data based on a collection id
 * @param {string} collectionId id of the collection
 * @param {string} [segmentationValue] optional segment to get a different config
 * @returns {any} raw collection
 */
const getCollection = async (collectionId, segmentationValue) => {
  segmentationStoredValue = segmentationValue;
  const baseUrl = await getBaseUrl();
  const fetchUrl = `${baseUrl}/${collectionPath}/${collectionId}`;
  const data = await fetchWithTimeout(fetchUrl, errorHandler);
  if (!data || data.error) {
    return { error: data?.error || 'Error fetching data' };
  }
  return data;
};

export default {
  getChannelData,
  getEpisodeById,
  getMovieById,
  getMovieCategories,
  getMovieData,
  getMovieDataById,
  getMovies,
  getMoviesByCategory,
  getTvListings,
  getChannelTvListings,
  getTvShows,
  getTvSeasonEpisodesById,
  getTvShowData,
  getTvShowById,
  getTvShowEpisodesById,
  getTvShowSeasonsById,
  getTvShowsByCategory,
  searchMovies,
  searchShows,
  signIn,
  signOut,
  validateToken,
  getItemsByQuery,
  getCollection
};
