import { Profile, ProfileType } from '#/interfaces/Profile';

/**
 * Check to see if the input dir parameter is RTL.
 *
 * @memberof module:base/util
 * @param {Profile} [profile] - the current Profile
 * @return {Boolean} True if the profile is for the kids type, false otherwise.
 */
const isProfileKids = (profile?: Profile) => profile?.type === ProfileType.KIDS;

export default isProfileKids;
