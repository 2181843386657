import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';

import getResolution from '#/utils/getResolution';
import toCamelCase from '#/utils/toCamelCase';
import { R720p } from '#/theme/variables/breakpoints';
import { ACCEDO_CONTROL_CONTAINER_TEMPLATES } from '#/config/constants';

import useI18n from '#/hooks/useI18n';
import interpolateTextWithObject from '#/utils/interpolateTextWithObject';

import styles from './Container.scss';

const {
  hero,
  continueWatching,
  carouselPortrait,
  carouselWide,
  carouselCategoryPortrait,
  carouselCategoryWide,
  gridHorizontalPortrait,
  gridHorizontalWide,
  gridVerticalPortrait,
  gridVerticalWide,
  carouselViewAllPortrait,
  carouselCategories,
  filterSort,
  carouselJumpRecommendation,
  channelsRail
} = ACCEDO_CONTROL_CONTAINER_TEMPLATES;

const Container = ({
  contextData,
  children,
  displayText,
  id,
  style,
  template
}) => {
  const { currentLocale = {} } = useI18n();
  const { dir } = currentLocale;
  const templateClassName = styles[toCamelCase(template)] || '';
  const { width } = getResolution();

  displayText = interpolateTextWithObject(displayText, contextData);
  const fontStyle = size => ({
    fontSize: `${size}px`,
    lineHeight: `${size}px`
  });

  const customStyle = {
    ...style,
    ...fontStyle(R720p >= width ? 28 : 36)
  };

  return (
    <div
      className={`${styles.outerContainer} ${templateClassName}`}
      style={customStyle}
    >
      {displayText && (
        <div id={id} className={styles.containerTitle} dir={dir}>
          {displayText}
        </div>
      )}
      <div
        id={displayText ? null : id}
        className={`${styles.innerContainer} ${styles[dir]}`}
      >
        {children}
      </div>
    </div>
  );
};

Container.propTypes = {
  contextData: PropTypes.object,
  children: PropTypes.node,
  displayText: PropTypes.string,
  id: PropTypes.string,
  style: PropTypes.object,
  template: PropTypes.oneOf([
    hero,
    continueWatching,
    carouselPortrait,
    carouselWide,
    carouselCategoryPortrait,
    carouselCategoryWide,
    gridHorizontalPortrait,
    gridHorizontalWide,
    gridVerticalPortrait,
    gridVerticalWide,
    carouselViewAllPortrait,
    carouselCategories,
    filterSort,
    carouselJumpRecommendation,
    channelsRail
  ])
};

export default React.memo(Container, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps)
);
