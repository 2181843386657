import ovp from '#/providers/ovp';
import { PROVIDER_TYPE } from '#/config/constants';
import Season from '#/models/season/season';
import { getSegmentationCachePart } from '#/utils/segmentationUtils';
import fetcher from '../../helpers/fetcher';

/**
 * @module services/season
 * @description
 * Service to handle the fetch of season data
 */

/**
 * Season Services
 * @param {string} id TVShow ID
 * @param {string} [segmentationValue] optional segment to get a different config
 * @returns {Promise<Array<any>>} Seasons
 */
const getSeasons = async (id, segmentationValue) => {
  const segmentationCachePart = getSegmentationCachePart(segmentationValue);
  const rawSeasons = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-show-seasons-${id}${segmentationCachePart}`,
    fetchFn: () => ovp.getTvShowSeasonsById(id, segmentationValue)
  });
  return rawSeasons?.entries?.map(rawSeason => Season(rawSeason));
};

/**
 * Get a single Season
 * @param {String} seasonId Season ID
 * @param {String} tvShowId TVShow ID
 * @param {string} [segmentationValue] optional segment to get a different config
 * @returns {Promise<Object>} Season
 */
const getSeason = async (seasonId, tvShowId, segmentationValue) => {
  const rawSeasons = await getSeasons(tvShowId, segmentationValue);
  const tvSeason =
    rawSeasons?.length && rawSeasons?.find(season => season.id === seasonId);
  return Season(tvSeason);
};

export { getSeasons, getSeason };
