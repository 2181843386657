import React, { useMemo } from 'react';
import useNotificationSystem from '#/hooks/useNotificationSystem';
import NotificationStack from '#/components/NotificationStack/NotificationStack';

import {
  NOTIFICATION_POSITIONS,
  NOTIFICATION_TYPES,
  NOTIFICATION_GROW_VALUES,
  NotificationExtendedType
} from '#/interfaces/Notification';

const NotificationManager = () => {
  const { notifications = [] } = useNotificationSystem();

  const groupedNotifications = useMemo(() => {
    const topstart = [] as NotificationExtendedType[];
    const topend = [] as NotificationExtendedType[];
    const bottomstart = [] as NotificationExtendedType[];
    const bottomend = [] as NotificationExtendedType[];
    notifications.forEach(notification => {
      if (notification.position === NOTIFICATION_POSITIONS.topstart) {
        topstart.push(notification);
      }
      if (notification.position === NOTIFICATION_POSITIONS.topend) {
        topend.push(notification);
      }
      if (notification.position === NOTIFICATION_POSITIONS.bottomstart) {
        bottomstart.push(notification);
      }
      if (notification.position === NOTIFICATION_POSITIONS.bottomend) {
        bottomend.push(notification);
      }
      // Default position
      if (!notification.position) {
        bottomend.push(notification);
      }
    });
    return {
      topstart,
      topend,
      bottomstart,
      bottomend
    };
  }, [notifications]);
  return (
    <>
      <NotificationStack
        notifications={groupedNotifications?.topstart}
        position={NOTIFICATION_POSITIONS.topstart}
        grow={NOTIFICATION_GROW_VALUES.down}
      />
      <NotificationStack
        notifications={groupedNotifications?.topend}
        position={NOTIFICATION_POSITIONS.topend}
        grow={NOTIFICATION_GROW_VALUES.down}
      />
      <NotificationStack
        notifications={groupedNotifications?.bottomstart}
        position={NOTIFICATION_POSITIONS.bottomstart}
        grow={NOTIFICATION_GROW_VALUES.up}
      />
      <NotificationStack
        notifications={groupedNotifications?.bottomend}
        position={NOTIFICATION_POSITIONS.bottomend}
        grow={NOTIFICATION_GROW_VALUES.up}
      />
    </>
  );
};

export default NotificationManager;

export { NOTIFICATION_TYPES, NOTIFICATION_POSITIONS };
