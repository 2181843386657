/**
 * @module providers/deviceStorage
 *
 * @description
 * Implements XDK Core storage class to use the proper local storage for each device.
 *
 * @see https://github.com/Accedo-Global-Solutions/xdk/tree/main/packages/xdk-core/src/interfaces/Storage.js
 *
 * Feel free to fill these methods with app's logic having in mind
 * to preserve names, params and return types since this service
 * can be used by hooks, high order components, or other React API.
 *
 * Also remove the helper methods to make it clean if it doesn't
 * make sense in your code.
 */

import { device } from '@accedo/xdk-core';

const LOCAL = 'local';

/**
 * Get values from  storage based on a given. key
 * @param {string} key Unique key. Identifies the value that should return from device storage.
 * @returns {T} Data to return from device storage.
 */
export const get = async <T>(key: string): Promise<T | null> => {
  const { storageManager } = device;
  const storage = await storageManager.getStorage(LOCAL);
  const data = storage.get(key);
  return (data && (JSON.parse(data) as T)) || null;
};

/**
 * Clear values from storage based on the given key.
 * @param {string} key Unique key. Identifies the value to be clear from device storage.
 * @returns {void} Void
 */
export const clear = async (key: string) => {
  const { storageManager } = device;
  const storage = await storageManager.getStorage(LOCAL);
  storage.set(key, '');
};

/**
 * Set values into storage.
 * @param {string} key Unique key. Identifies the value to insert into the device storage.
 * @param {T} data Data to insert into device storage.
 * @returns {void} Void
 */
export const set = async <T>(key: string, data: T) => {
  try {
    const { storageManager } = device;
    const storage = await storageManager.getStorage(LOCAL);
    storage.set(key, JSON.stringify(data));
  } catch (e) {
    console.error(`[ERROR] Unable to store user data`);
    throw e;
  }
};
