import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef
} from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { ProfileContext } from '#/context/ProfileContext';
import { getPageLayout, getThemeByPage } from '#/services/cms';
import useI18n from '#/hooks/useI18n';
import { screenView } from '#/services/analytics';
import Skeleton from '#/components/Skeletons';
import { getSegmentationValue } from '#/utils/segmentationUtils';
import setCssVariables from '#/utils/setCssVariables';
import defaultLocalTheme from '#/theme/defaultTheme.json';

const PageLayout = ({
  View,
  screenType,
  analyticsOnPageLayoutLoad,
  ...rest
}) => {
  const { pathname: route } = useLocation();
  const routeForPage = useRef('');
  const { currentProfile } = useContext(ProfileContext);
  const { currentLocale } = useI18n();
  const { code: localeCode } = currentLocale || {};
  const segmentationValue = getSegmentationValue(currentProfile);
  const [pageInfo, setPageInfo] = useState({
    id: '',
    displayText: null,
    theme: null,
    containers: null,
    assetData: null,
    errorMessage: null,
    failedToLoad: false,
    loaded: false
  });

  const getPageData = useCallback(async () => {
    let page = {};
    let theme = defaultLocalTheme;
    try {
      page = await getPageLayout({
        route,
        locale: currentLocale.code,
        segmentationValue
      });

      const pageTheme = await getThemeByPage({
        route,
        locale: currentLocale.code,
        segmentationValue
      });

      if (Object.keys(pageTheme)) {
        theme = pageTheme;
      }

      routeForPage.current = route;
    } catch (e) {
      console.warn(`[debug] No page available`);
    }
    try {
      setCssVariables(theme);
      setPageInfo(prevInfo => ({
        ...prevInfo,
        loaded: true,
        theme,
        ...page // id, displayText, containers
      }));
    } catch (error) {
      setPageInfo(prevInfo => ({
        ...prevInfo,
        failedToLoad: true,
        errorMessage: error
      }));
    }
  }, [currentLocale.code, route, segmentationValue]);

  useEffect(() => {
    if (routeForPage.current !== route) {
      setPageInfo(prevInfo => ({
        ...prevInfo,
        loaded: false
      }));
    }
  }, [route]);

  useEffect(() => {
    if (localeCode) {
      getPageData();
    }
  }, [getPageData, localeCode]);

  useEffect(() => {
    if (pageInfo.loaded && analyticsOnPageLayoutLoad) {
      const screenRoute = route;
      screenView({ screenType, screenRoute });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo?.loaded]);

  if (!pageInfo?.loaded) {
    return <Skeleton pageSkeleton />;
  }

  return (
    <View
      pageInfo={pageInfo}
      screenType={screenType}
      segmentationValue={segmentationValue}
      {...rest}
    />
  );
};

PageLayout.propTypes = {
  View: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.object]),
  analyticsOnPageLayoutLoad: PropTypes.bool,
  screenType: PropTypes.string
};

export const PageInfoPropTypes = PropTypes.shape({
  id: PropTypes.string,
  displayText: PropTypes.string,
  loaded: PropTypes.bool,
  failedToLoad: PropTypes.bool,
  errorMessage: PropTypes.string,
  containers: PropTypes.array,
  assetData: PropTypes.object,
  theme: PropTypes.object
});

export default PageLayout;
