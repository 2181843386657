const ACCEDO_ONE_URL = 'https://cdn.one.accedo.tv/files';
const CLOUDINARY_ACCEDO_ONE_URL = (height, quality) =>
  `https://res.cloudinary.com/accedotv/image/upload/h_${height}/q_${quality}/accedo-one-control`;
const replaceImgUrl = (imgUrl, currentPartial, newPartial) => {
  return imgUrl?.replace(currentPartial, newPartial);
};

/**
 * App environment
 */
const ENVIRONMENT = process.env.NODE_ENV;

/**
 * Quality in local environment
 */
const DEV_QUALITY = 20;

/**
 * Maximum Viewport Size of the application to ensure no image is returned with a bigger Width
 */
const MAX_VIEWPORT_SIZE = 1920;

export const getCloudinaryImage = ({ imgUrl, imgWidth, width }) => {
  const imageWidth = Math.min(MAX_VIEWPORT_SIZE, imgWidth || width);
  // Doing the image's template URL transform
  const resizeTemplateUrl = imgUrl?.replace(
    '{template}',
    ENVIRONMENT === 'development'
      ? // Applying a quality only in the dev environment
        `w:${imageWidth}/q:${DEV_QUALITY}`
      : `w:${imageWidth}`
  );

  return resizeTemplateUrl;
};

const getCloudinaryItem = (item, height) => {
  const { image } = item;
  if (!image) {
    return item;
  }
  const cloudinaryImages = image.map(img => {
    const { fileUrl } = img;
    const cloudinaryUrl = replaceImgUrl(
      fileUrl,
      ACCEDO_ONE_URL,
      CLOUDINARY_ACCEDO_ONE_URL(height, 40)
    );
    return {
      ...img,
      fileUrl: cloudinaryUrl
    };
  });

  return {
    ...item,
    image: cloudinaryImages
  };
};

export const getCloudinaryItems = (items, height) => {
  return items.map(item => getCloudinaryItem(item, height));
};
