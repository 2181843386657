/**
 * @module providers/bookmark
 *
 * @description
 * This providers handles bookmark items. It implements XDK Core
 * storage class to use the proper local storage for each device.
 *
 * @see https://github.com/Accedo-Global-Solutions/xdk/tree/main/packages/xdk-core/src/interfaces/Storage.js
 *
 * Feel free to fill these methods with app's logic having in mind
 * to preserve names, params and return types since this service
 * can be used by hooks, high order components, or other React API.
 *
 * Also remove the helper methods to make it clean if it doesn't
 * make sense in your code.
 */

import { device } from '@accedo/xdk-core';
import { mapToString, stringToMap } from '#/utils/dataConverterHelper';
import { CONTAINER_ITEM_TYPES } from '#/config/constants';
import { getSeasons, getEpisodes, getNextEpisode } from '#/services/ovp';
import appConfig from '#/config/app';
import { getPlaybackConfig } from '#/providers/shared/control/config';

import { buildStorageKey } from '#/utils/storageManager';

const {
  application: {
    player: {
      watchlistPercentageThreshold: defaultWatchlistPercentageThreshold = 98
    } = {}
  } = {}
} = appConfig || {};

/** @type {String} */
const BOOKMARK_KEY = 'vdkctv-bookmark';

/** @type {String} */
const STORAGE_STRATEGY = 'local';

/**
 * Helper method to get string values from storage
 * @param {string} profileId Unique favoriteKey. Identify the user in the database.
 * @returns {Promise<Map>} Promise with Bookmark storage items
 */
export const getBookmarkObjectFromUser = async profileId => {
  const { storageManager } = device;
  const storage = await storageManager.getStorage(STORAGE_STRATEGY);
  return stringToMap(storage.get(buildStorageKey(BOOKMARK_KEY, profileId)));
};

/**
 * Helper method to set a new string into storage
 * @param {string} profileId user/profile identifier. Identify the user in the bookmark store.
 * @param {MovieBookmark | EpisodeBookmark} value Bookmark item from the possible interfaces
 * @returns {Promise<boolean>} Promise with a flag whether the item has been added succesfully or not
 */
export const saveBookmark = async (profileId, value) => {
  try {
    const { storageManager } = device;
    const storage = await storageManager.getStorage(STORAGE_STRATEGY);
    const bookmarks = (await getBookmarkObjectFromUser(profileId)) || new Map();
    bookmarks.set(value.id, JSON.stringify({ ...value }));
    storage.set(
      buildStorageKey(BOOKMARK_KEY, profileId),
      mapToString(bookmarks)
    );
    return true;
  } catch (e) {
    console.error(`[debug] Error adding Bookmark for ${profileId}: `, value);
    return false;
  }
};

const __removeBookmarks = async (profileId, ids) => {
  try {
    const { storageManager } = device;
    const storage = await storageManager.getStorage(STORAGE_STRATEGY);
    const bookmarks = (await getBookmarkObjectFromUser(profileId)) || new Map();
    ids.forEach(id => bookmarks.delete(id));
    storage.set(
      buildStorageKey(BOOKMARK_KEY, profileId),
      mapToString(bookmarks)
    );
    return true;
  } catch (e) {
    console.error(`[debug] Error removing Bookmarks for ${profileId}: `, ids);
    return false;
  }
};

/**
 * It returns the bookmark stored for the provider Content Entry or undefined
 * @param {string} profileId user/profile identifier. Identify the user in the bookmark store.
 * @param {Object} entry The specific entry, it should contain and id
 * @returns {Promise<Object|undefined>} Promise with all Bookmark storage items
 */
export const getBookmark = async (profileId, entry) => {
  const bookmarks = (await getBookmarkObjectFromUser(profileId)) || new Map();
  return bookmarks.get(entry.id);
};

/**
 * It returns the bookmark stored for the provider Content Entry for a certain show by its id
 * @param {string} profileId user/profile identifier. Identify the user in the bookmark store.
 * @param {Object} showId The specific show identifier
 * @returns {Promise<Map<Bookmark|undefined>>} Promise with all Bookmark storage items
 */
export const getBookmarksForShow = async (profileId, showId) => {
  const bookmarks = (await getBookmarkObjectFromUser(profileId)) || new Map();
  const showEpisodes = new Map();
  bookmarks.forEach(asset => {
    if (
      asset.assetType === CONTAINER_ITEM_TYPES.Episode &&
      asset.showId === showId
    ) {
      showEpisodes.set(asset.id, asset);
    }
  });
  return showEpisodes;
};

/**
 * It returns the continue watching items of a user
 * @param {string} profileId user/profile identifier. Identify the user in the bookmark store.
 * @returns {Promise<Map<Bookmark|undefined>>} Promise with all Bookmark storage items
 */
export const getContinueWatchingItems = async profileId => {
  const bookmarks = await getBookmarkObjectFromUser(profileId);
  const groupedEpisodeBookmarks = new Map();
  const moviesBookmarks = new Map();
  const latestEpisodeBookmarks = new Map();

  bookmarks.forEach(bookmark => {
    if (bookmark.assetType === 'episode' || bookmark.showId) {
      let showBookmarkMap;
      if (!groupedEpisodeBookmarks.has(bookmark.showId)) {
        showBookmarkMap = new Map();
        showBookmarkMap.set(bookmark.id, bookmark);
        groupedEpisodeBookmarks.set(bookmark.showId, showBookmarkMap);
        return;
      }
      showBookmarkMap = groupedEpisodeBookmarks.get(bookmark.showId);
      showBookmarkMap.set(bookmark.id, bookmark);
      groupedEpisodeBookmarks.set(bookmark.showId, showBookmarkMap);
      return;
    }
    moviesBookmarks.set(bookmark.id, bookmark);
  });
  // The usage of item[1] is due to the map nature
  groupedEpisodeBookmarks.forEach((episodeBookmarkGroup, showId) => {
    const sortedEpisodesBookmarkGroup = [
      ...episodeBookmarkGroup.entries()
    ].sort((episodeA, episodeB) => {
      return episodeA[1].seasonNumber === episodeB[1].seasonNumber
        ? episodeB[1].episodeNumber - episodeA[1].episodeNumber
        : episodeB[1].seasonNumber - episodeA[1].seasonNumber;
    });
    const latestEpisodeForGroup = sortedEpisodesBookmarkGroup[0];
    latestEpisodeBookmarks.set(showId, latestEpisodeForGroup[1]);
  });
  const continueWatchingItems = new Map([
    ...moviesBookmarks,
    ...latestEpisodeBookmarks
  ]);
  // From Map to pure Array so index and lenght is fixed and can be handled by MyContent page
  return continueWatchingItems;
};

const __removeShowBookmarks = async (profileId, showId) => {
  const bookmarksForShow = await getBookmarksForShow(profileId, showId);
  const bookmarkIdsToRemove = bookmarksForShow.keys?.()?.toArray?.() || [];
  __removeBookmarks(profileId, bookmarkIdsToRemove);
};

/**
 * Helper method to get Episode to watch
 * @param {object} params parameters object
 * @param {string} params.profileId Unique favoriteKey. Identify the user in the database.
 * @param {string} params.showId Identifier for the Show
 * @param {string} [params.segmentationValue] - optional segment to get a different config
 * @returns {Promise<Object>} Promise with Episode
 */
export const getNextEpisodeToPlayForShow = async ({
  profileId,
  showId,
  segmentationValue
}) => {
  const cwItems = await getContinueWatchingItems(profileId);
  const continueWatchingEpisodeForShow = cwItems.get(showId);
  if (!continueWatchingEpisodeForShow) {
    const seasons = await getSeasons(showId, segmentationValue);
    const firstSeason = seasons[0];
    const episodesFromSeason = await getEpisodes(firstSeason.id);
    const firstEpisode = episodesFromSeason[0];
    return {
      ...firstEpisode,
      progress: 0,
      assetType: 'episode',
      resumeTime: 0
    };
  }
  const playbackConfig = await getPlaybackConfig();
  const {
    watchlistPercentageThreshold = defaultWatchlistPercentageThreshold
  } = playbackConfig;
  if (continueWatchingEpisodeForShow.progress < watchlistPercentageThreshold) {
    return continueWatchingEpisodeForShow;
  }
  const seasons = await getSeasons(showId, segmentationValue);
  const latestWatchedEpisodeSeason = seasons.find(
    season => season.id === continueWatchingEpisodeForShow.seasonId
  );
  const episodesFromCurrentSeason = await getEpisodes(
    latestWatchedEpisodeSeason.id
  );
  const nextEpisode = await getNextEpisode(
    continueWatchingEpisodeForShow,
    episodesFromCurrentSeason,
    seasons
  );
  if (nextEpisode) {
    return {
      ...nextEpisode,
      progress: 0,
      assetType: 'episode',
      resumeTime: 0
    };
  }
  const firstSeason = seasons[0];
  const episodesFromSeason = await getEpisodes(firstSeason.id);
  const firstEpisode = episodesFromSeason[0];
  // Clear show related bookmark info
  await __removeShowBookmarks(profileId, showId);
  return {
    ...firstEpisode,
    progress: 0,
    assetType: 'episode',
    resumeTime: 0
  };
};

/**
 * Helper method to get Movie play status
 * @param {string} profileId Unique favoriteKey. Identify the user in the database.
 * @param {Object} movie Movie Object
 * @returns {Promise<Object>} Promise with Episode
 */
export const getMoviePlayStatusBookmark = async (profileId, movie) => {
  const bookmark = await getBookmark(profileId, movie);
  if (!bookmark) {
    return undefined;
  }
  const playbackConfig = await getPlaybackConfig();
  const {
    watchlistPercentageThreshold = defaultWatchlistPercentageThreshold
  } = playbackConfig;
  const { progress: currentProgress } = bookmark;
  if (currentProgress >= watchlistPercentageThreshold) {
    return {
      ...bookmark,
      progress: 0,
      assetType: 'movie',
      resumeTime: 0
    };
  }
  return bookmark;
};
