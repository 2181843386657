import { Profile, ProfileType } from '#/interfaces/Profile';

const profileFallback: Profile = {
  id: '',
  name: '',
  image: '',
  type: '',
  pin: ''
};

class ProfileBuilder {
  profile: Profile;

  constructor(profile?: Profile) {
    if (profile) {
      this.profile = profile;
      return;
    }

    this.profile = profileFallback;
  }

  _getProfileType(rawData: any) {
    let type = ProfileType.DEFAULT;

    if (rawData.isKids) {
      type = ProfileType.KIDS;
    }

    if (rawData.isPrincipal) {
      type = ProfileType.PRIMARY;
    }

    return type;
  }

  fromRawData(rawData: any) {
    if (!rawData) {
      throw Error('ProfileBuilder - raw data should be valid.');
    }

    const { id, name, avatar, pin } = rawData;

    const newProfile: Profile = {
      id,
      name,
      image: avatar,
      type: this._getProfileType(rawData),
      pin,
      pinEnabled: !!pin
    };

    this.profile = newProfile;
    return this;
  }

  toRawData() {
    const { id, name, image, type, pin } = this.profile;

    const rawData = {
      id,
      name,
      avatar: image,
      isKids: type === ProfileType.KIDS,
      isPrincipal: type === ProfileType.PRIMARY,
      pin
    };

    return rawData;
  }

  create(): Profile {
    const newProfile: Profile = this.profile;
    return newProfile;
  }
}

export default ProfileBuilder;
