import Cache from './Cache';

const USE_CACHE = __USE_APPLICATION_CACHE__;

const fetcher = async ({ fetchFn, cacheId } = {}) => {
  const cache = new Cache();

  if (cacheId && cache.has(cacheId)) {
    return cache.get(cacheId);
  }

  const fetchResponse = fetchFn();

  if (USE_CACHE && cacheId) {
    cache.set(cacheId, fetchResponse);
    fetchResponse.catch(e => {
      console.error('[debug] Error getting data:', e);
      cache.delete(cacheId);
      return Promise.reject(new Error(e));
    });
  }

  return fetchResponse;
};

export default fetcher;
