import React from 'react';
import PropTypes from 'prop-types';
import useI18n from '#/hooks/useI18n';
import styles from './skeletons.scss';

const ITEM_TYPE = {
  PORTRAIT: 'portrait',
  LANDSCAPE: 'landscape'
};

// DetailSkeleton returns an empty episode/movie details page to simulate loading.
// It also needs an itemType to assign CSS styles.
const DetailSkeleton = ({ itemType }) => {
  const { currentLocale = {} } = useI18n();
  const { dir } = currentLocale;
  const orientation =
    itemType === ITEM_TYPE.PORTRAIT ? 'portrait' : 'landscape';
  const baseColorStyle = styles.baseColor;

  return (
    <div
      className={`${styles.descriptionContainer} ${styles[orientation]} ${styles[dir]}`}
      dir={dir}
    >
      <div className={styles[`${orientation}DescriptionSkeleton`]}>
        <div className={`${styles.textSkeleton} ${baseColorStyle}`} />
        <div className={`${styles.button} ${baseColorStyle}`} />
        <div className={`${styles.button} ${baseColorStyle}`} />
      </div>
    </div>
  );
};

DetailSkeleton.propTypes = {
  itemType: PropTypes.oneOf([ITEM_TYPE.LANDSCAPE, ITEM_TYPE.PORTRAIT])
    .isRequired
};

DetailSkeleton.defaultProps = {
  itemType: ITEM_TYPE.PORTRAIT
};

DetailSkeleton.item_type = ITEM_TYPE;

export default DetailSkeleton;
