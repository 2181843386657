import React from 'react';
import { LoaderIcon } from '#/components/Icons/Icons';
import styles from './loader.scss';

const Loader = () => {
  return (
    <div className={styles.loaderContainer}>
      <LoaderIcon className={styles.loaderIcon} />
    </div>
  );
};

export default Loader;
