import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Ellipsis from 'react-ellipsis-pjs';
import { withFocus } from '@accedo/vdkweb-navigation';

import getImageByType from '#/utils/getImageByType';
import { IMAGE_TYPE, ROUTES } from '#/config/constants';
import ImageWithPreload from '#/components/ImageWithPreload/ImageWithPreload';
import useHistoryPush from '#/hooks/history/useHistoryPush';
import componentStyle from './continueWatchingItem.scss';

const WIDTH_TO_SUBTRACT = 2;
const HEIGHT_TO_SUBTRACT = 6;

const ContinueWatchingItem = props => {
  const { data, width, height, nav } = props;
  const [poster, setPoster] = useState('');
  const historyPush = useHistoryPush();

  useEffect(() => {
    const image = getImageByType(data.images, IMAGE_TYPE.POSTER);

    setPoster(image);
  }, [data.images]);

  data.images = [{ url: props.data.coverUrl }];

  if (data.assetType === 'episode') {
    data.title = data.showTitle;
  }

  const itemStyle = {
    ...(width ? { width } : {}),
    ...(height ? { height } : {})
  };

  const imgContainerSize = {
    ...(width ? { width: width - WIDTH_TO_SUBTRACT } : {}),
    ...(height ? { height: height - HEIGHT_TO_SUBTRACT } : {})
  };

  const imgContainerClass = nav.isFocused
    ? `${componentStyle.imgContainer} ${componentStyle.imgContainerFocused}`
    : componentStyle.imgContainer;

  const titleContainerClass = nav.isFocused
    ? `${componentStyle.title} ${componentStyle.titleFocused}`
    : componentStyle.title;

  const goToPlayer = useCallback(() => {
    historyPush({
      path: ROUTES.player,
      state: {
        asset: {
          ...data
        },
        resumeTime: data.resumeTime,
        videoUrl: data.videoUrl
      }
    });
  }, [data, historyPush]);

  return (
    <div
      style={itemStyle}
      className={componentStyle.container}
      onClick={() => goToPlayer()}
      onMouseEnter={nav.onMouseOver}
      onMouseLeave={nav.onMouseOut}
      role="button"
    >
      <div className={imgContainerClass} style={imgContainerSize}>
        <ImageWithPreload src={poster} className={componentStyle.cardImg} />

        {data.progress > 0 && (
          <div className={componentStyle.progress}>
            <span style={{ width: `${data.progress}%` }} />
          </div>
        )}

        <div className={titleContainerClass}>
          <Ellipsis lines={1} text={data.title} />
        </div>
      </div>
    </div>
  );
};

ContinueWatchingItem.propTypes = {
  data: PropTypes.object,
  height: PropTypes.any,
  width: PropTypes.any,
  nav: PropTypes.object
};

export default withFocus(ContinueWatchingItem);
