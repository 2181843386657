import xdk, { device } from '@accedo/xdk-core';

const addDeviceData = () => {
  const deviceYear: string = xdk.system.getDeviceYear();
  const { platform } = device;

  document.documentElement.setAttribute(
    'data-device',
    `${platform}_${deviceYear}`
  );
};

export default addDeviceData;
