export enum ProfileType {
  PRIMARY = 'PRIMARY',
  DEFAULT = 'DEFAULT',
  KIDS = 'KIDS'
}

export type ProfileTypeKeys = keyof typeof ProfileType;

export interface Profile {
  id: string;
  name: string;
  image: string;
  type: string;
  pin: string;
  pinEnabled?: boolean;
}
