/**
 * @module services/config
 * @description
 * Service to handle the fetch of config.
 * @return {VikiElevateConfig} The modelised config file.
 */
import configModel from '#/models/config';
import { ConfigInput } from '#/interfaces/ConfigInput';
import { PROVIDER_TYPE } from '#/config/constants';
import fetcher from '#/services/helpers/fetcher';
import configProvider from '#/providers/config';
import { Config } from '#/interfaces/Config';
import { getSegmentationCachePart } from '#/utils/segmentationUtils';

/**
 * Returns all the configuration of the application
 * @param {string} [segmentationValue] optional segment to get a different config
 * @returns {Promise<object>} The entire configuration
 */
const getConfiguration = async (
  segmentationValue?: string
): Promise<Config> => {
  const segmentationValueCacheValue = getSegmentationCachePart(
    segmentationValue
  );
  const cacheId = `${PROVIDER_TYPE.config}${segmentationValueCacheValue}-config`;
  const rawConfig: ConfigInput = await fetcher({
    cacheId,
    fetchFn: () => configProvider.getConfiguration(segmentationValue)
  });
  const config: Config = configModel(rawConfig);

  return config;
};

/**
 * Returns the Application related configuration
 * @param {string} [segmentationValue] optional segment to get a different config
 * @returns {Promise<object>} The Application configuration
 */
const getApplicationConfiguration = async (
  segmentationValue?: string
): Promise<any> => {
  const config = await getConfiguration(segmentationValue);
  return config.application;
};
/**
 * Returns the Application feature flags
 * @param {string} [segmentationValue] optional segment to get a different config
 * @returns {Promise<object>} The Application feature flags
 */
const getFeatureFlags = async (segmentationValue?: string): Promise<any> => {
  const config = await getConfiguration(segmentationValue);
  return config.featureFlags;
};
/**
 * Returns the Provider configuration
 * @param {string} [segmentationValue] optional segment to get a different config
 * @returns {Promise<object>} The Provider config
 */
const getProvidersConfiguration = async (
  segmentationValue: string
): Promise<any> => {
  const config = await getConfiguration(segmentationValue);
  return config.providers;
};

export {
  getConfiguration,
  getApplicationConfiguration,
  getFeatureFlags,
  getProvidersConfiguration
};
