import React, { useState, createContext, useCallback } from 'react';
import PropTypes from 'prop-types';

const I18nContext = createContext();
const I18nSetContext = createContext();

const I18nProvider = ({ children }) => {
  const [state, setState] = useState({
    isInitiated: false,
    initiatedSegment: null,
    defaultLocale: 'en',
    currentLocale: {},
    locales: []
  });

  const updateContext = useCallback(config => {
    setState(s => ({
      ...s,
      ...config
    }));
  }, []);

  return (
    <I18nContext.Provider value={state}>
      <I18nSetContext.Provider value={updateContext}>
        {children}
      </I18nSetContext.Provider>
    </I18nContext.Provider>
  );
};

I18nProvider.propTypes = {
  children: PropTypes.node
};

export { I18nContext, I18nSetContext, I18nProvider };
