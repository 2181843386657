const HTTP_TIMEOUT = 5000; // 5 secs

/**
 * It does a fetch with a 5 secs timer. if in that time no answer, it fail cancel the request and fail
 *
 * @param {string} url the url to do the fetch
 * @param {any} errorHandler the error handler function
 *
 * @returns {Promise<any>} the returned json processes response, or an error
 */
const fetchWithTimeout = async (url: string, errorHandler: any) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), HTTP_TIMEOUT);
  try {
    const response = await fetch(url, {
      signal: controller.signal
    });
    if (!response.ok) {
      throw new Error(`${response.status} ${response.statusText}`);
    }
    return response.json();
  } catch (error) {
    errorHandler(error);
    return { error: `Error fetching data ${error}` };
  } finally {
    clearTimeout(timeoutId);
  }
};

export { fetchWithTimeout };
