import { useContext, useCallback } from 'react';
import { ConfigContext, ConfigSetcontext } from '#/context/ConfigContext';
import useAuthProfile from '#/hooks/useAuthProfile';
import {
  ConfigExtendedHook,
  ConfigExtended
} from '#/interfaces/ConfigExtended';
import { getConfiguration } from '#/services/config';
import { getDefaultTheme, getRoutes } from '#/services/cms';
import { getSegmentationValue } from '#/utils/segmentationUtils';

let configRequestPending = false;

const useAppConfig = (): ConfigExtendedHook => {
  const configContext = useContext(ConfigContext);
  const authContext = useAuthProfile();
  const { updateConfig } = useContext(ConfigSetcontext);

  if (updateConfig === undefined || configContext === undefined) {
    throw new Error('useAppConfig must be used within a ConfigProvider');
  }

  const getConfigData = useCallback(
    async profileContextParam => {
      if (configRequestPending || profileContextParam.currentProfileLoading) {
        return;
      }
      configRequestPending = true;
      let appConfig: ConfigExtended;
      try {
        const segmentationValue = getSegmentationValue(
          profileContextParam?.currentProfile
        );
        appConfig = (await getConfiguration(
          segmentationValue
        )) as ConfigExtended;
        const [firstPromise, secondPromise] = await Promise.allSettled([
          getDefaultTheme(segmentationValue),
          getRoutes(segmentationValue)
        ]);
        const { value: theme } = firstPromise as PromiseFulfilledResult<any>;
        const {
          value: routesMapping
        } = secondPromise as PromiseFulfilledResult<any[]>;
        appConfig.theme = theme;
        appConfig.routes = routesMapping;
        configRequestPending = false;
        updateConfig({ config: appConfig, requireConfigUpdate: false });
        return appConfig;
      } catch (error) {
        const config = { errorLoading: true };
        updateConfig({ config, requireConfigUpdate: false });
        return config;
      }
    },
    [updateConfig]
  );

  const getConfig = useCallback(() => {
    const { config, requireConfigUpdate } = configContext;
    if (!config || requireConfigUpdate) {
      getConfigData(authContext);
      return {};
    }
    return config;
  }, [configContext, getConfigData, authContext]);

  return {
    config: getConfig()
  };
};

export default useAppConfig;
