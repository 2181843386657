// Application-side configuration
import globalAppconfig from '#/config';
import control from './control/control';
// import local from './local/local';

const configControlInstance = control();
const appConfig = globalAppconfig.app;
// This is done to merge the local config with the config received from Control
const config = { ...appConfig, ...configControlInstance };
export default config;
