import fetch from '@accedo/vdkweb-fetch';

import config from '#/config';
import { getOVPConfig } from '#/providers/shared/control/config';
import local from '../local/local';

const { accedoOvpUrl } = config.app;

const errorHandler = error => console.error(error);

/**
 * @module providers/idp
 * @description
 * Provider IDP implementation for Accedo
 */

/**
 * A map with all the services used by this template.
 */
const services = {
  loginByCredentials: `/auth`,
  validateUser: token => `/auth/${token}/valid`,
  logout: token => `/auth/${token}`,
  profiles: `/user/profiles`,
  avatar: `/user/avatar/`,
  // different urls required on elevate for the auth microservice
  loginByPairingCode: `/pairing/check`, // TODO: update with pairing service for remote cases
  pairingCode: `/pairing/code` // TODO: update with pairing service for remote cases
};

const commonPutOptions = {
  method: 'PUT',
  headers: {
    'Content-Type': 'application/json'
  }
};

const commonDeleteOptions = {
  method: 'DELETE',
  headers: {
    'Content-Type': 'application/json'
  }
};

let commonPostOptions = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  }
};

const commonGetOptions = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json'
  }
};

const getBaseUrl = async segmentationValue => {
  const ovpConfiguration = await getOVPConfig(segmentationValue);
  return ovpConfiguration?.url || accedoOvpUrl; // TODO: update with idpUrl once is defined
};

const login = async (username, password) => {
  const baseUrl = await getBaseUrl();

  commonPostOptions = {
    ...commonPostOptions,
    headers: {
      ...commonPostOptions.headers,
      'X-User': username,
      'X-Password': password
    }
  };

  const options = commonPostOptions;

  return fetch(`${baseUrl}${services.loginByCredentials}`, options).then(
    async response => {
      // Fetch API only rejects a promise when a network error is encountered
      // so an error response needs to be thrown forward
      // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#Checking_that_the_fetch_was_successful
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const rawUser = await response.json();
      const extendedRawUser = {
        ...rawUser,
        username
      };
      await local._saveUser(extendedRawUser);
      return extendedRawUser;
    }
  );
};

const validateUser = async user => {
  const { token, userId } = user;
  const baseUrl = await getBaseUrl();
  const options = {
    ...commonGetOptions,
    headers: {
      ...commonGetOptions.headers,
      'X-UserId': userId
    }
  };

  return fetch(`${baseUrl}${services.validateUser(token)}`, options).then(
    response => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      return response.json().then(async () => user);
    }
  );
};

const logout = async token => {
  const options = commonDeleteOptions;
  const baseUrl = await getBaseUrl();

  await local._clearUser(); // Clear User from localStorage
  return fetch(`${baseUrl}${services.logout(token)}`, options).then(
    response => response.ok
  );
};

const getPairingCode = async deviceId => {
  const baseUrl = await getBaseUrl();
  const options = {
    ...commonPostOptions,
    body: JSON.stringify({ deviceId })
  };

  return fetch(`${baseUrl}${services.pairingCode}`, options).then(response =>
    response.json()
  );
};

const pair = async (deviceId, abortController = null) => {
  const baseUrl = await getBaseUrl();
  const options = {
    ...commonPostOptions,
    signal: abortController ? abortController.signal : null,
    body: JSON.stringify({ deviceId })
  };

  return fetch(`${baseUrl}${services.loginByPairingCode}`, options).then(
    response => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    }
  );
};

/**
 * Get profiles
 * @returns {Promise<Array<Profile>>} Profiles
 */
const getProfiles = async () => {
  const baseUrl = await getBaseUrl();
  return fetch(`${baseUrl}${services.profiles}`)
    .then(reponse => Promise.resolve(reponse.json()))
    .catch(errorHandler);
};

/**
 * Get profile
 * @param {String | number} id Profile ID
 * @returns {Promise<any>} getProfile object { profile }
 */
const getProfile = async id => {
  const baseUrl = await getBaseUrl();
  return fetch(`${baseUrl}${services.profiles}/${id}`)
    .then(reponse => Promise.resolve(reponse.json()))
    .catch(errorHandler);
};

/**
 * Create profile
 * @param {Profile} profile Profile
 * @returns {Promise<object>} Success/Fail
 */
const createProfile = async profile => {
  const baseUrl = await getBaseUrl();
  const options = {
    ...commonPostOptions,
    body: JSON.stringify(profile)
  };

  return fetch(`${baseUrl}${services.profiles}`, options)
    .then(reponse => Promise.resolve(reponse.json()))
    .catch(errorHandler);
};

/**
 * Update profile name
 * @param {String | number} id Profile ID
 * @param {String} name New profile name
 * @returns {Promise<object>} Success/Fail
 */
const updateProfileName = async (id, name) => {
  const baseUrl = await getBaseUrl();
  const options = {
    ...commonPutOptions,
    body: JSON.stringify({ name })
  };

  return fetch(`${baseUrl}${services.profiles}/${id}`, options)
    .then(reponse => Promise.resolve(reponse.json()))
    .catch(errorHandler);
};

/**
 * Update profile name
 * @param {String | number} id Profile ID
 * @param {String} avatar New profile avatar
 * @returns {Promise<object>} Success/Fail
 */
const updateProfileAvatar = async (id, avatar) => {
  const baseUrl = await getBaseUrl();
  const options = {
    ...commonPutOptions,
    body: JSON.stringify({ avatar })
  };

  return fetch(`${baseUrl}${services.profiles}/${id}`, options)
    .then(reponse => Promise.resolve(reponse.json()))
    .catch(errorHandler);
};

/**
 * Delete profile
 * @param {String | number} id Profile ID
 * @returns {Promise<object>} Success/Fail
 */
const deleteProfile = async id => {
  const baseUrl = await getBaseUrl();
  return fetch(`${baseUrl}${services.profiles}/${id}`, commonDeleteOptions)
    .then(reponse => Promise.resolve(reponse.json()))
    .catch(errorHandler);
};

/**
 * Get avatars
 * @returns {Promise<Array<Avatar>>} Avatars
 */
const getAvatars = async () => {
  const baseUrl = await getBaseUrl();
  return fetch(`${baseUrl}${services.avatar}`)
    .then(reponse => Promise.resolve(reponse.json()))
    .catch(errorHandler);
};

/**
 * Setup profile pin
 * @param {String | number} profileId Profile ID
 * @param {String} pin New pin
 * @returns {Promise<object>} Success/Fail
 */
const createPIN = async (profileId, pin) => {
  const baseUrl = await getBaseUrl();
  const options = {
    ...commonPutOptions,
    body: JSON.stringify({ pin })
  };

  return fetch(`${baseUrl}${services.profiles}/${profileId}`, options)
    .then(reponse => Promise.resolve(reponse.json()))
    .catch(errorHandler);
};

/**
 * Update profile pin
 * @param {String | number} profileId Profile ID
 * @param {String} pin New pin
 * @returns {Promise<object>} Success/Fail
 */
const updatePIN = async (profileId, pin) => {
  const baseUrl = await getBaseUrl();
  const options = {
    ...commonPutOptions,
    body: JSON.stringify({ pin })
  };

  return fetch(`${baseUrl}${services.profiles}/${profileId}`, options)
    .then(reponse => Promise.resolve(reponse.json()))
    .catch(errorHandler);
};

/**
 * Delete profile pin
 * @param {String | number} profileId Profile ID
 * @returns {Promise<object>} Success/Fail
 */
const deletePIN = async profileId => {
  const baseUrl = await getBaseUrl();
  const options = {
    ...commonPutOptions,
    body: JSON.stringify({ pin: '' })
  };

  return fetch(`${baseUrl}${services.profiles}/${profileId}`, options)
    .then(reponse => Promise.resolve(reponse.json()))
    .catch(errorHandler);
};

export default {
  login,
  validateUser,
  logout,
  getPairingCode,
  pair,
  getProfiles,
  getProfile,
  deleteProfile,
  updateProfileName,
  updateProfileAvatar,
  createProfile,
  getAvatars,
  createPIN,
  updatePIN,
  deletePIN
};
