import EpisodeModel from '#/models/episode/episode';
import MovieModel from '#/models/movie/movie';
import { Episode as EpisodeType } from '#/interfaces/Episode';
import { Movie as MovieType } from '#/interfaces/Movie';
import { EpisodeBookmark } from '#/interfaces/EpisodeBookmark';
import { MovieBookmark } from '#/interfaces/MovieBookmark';
import { CONTAINER_ITEM_TYPES } from '#/config/constants';

/**
 * @description
 * Use bookmark builder to handle bookmark instances.
 * @constructor
 * @param {string} mediaContent - A media content (Ex: Movie or Episode).
 */
class BookmarkBuilder<T extends EpisodeType | MovieType> {
  mediaContent: any; // TODO: Should be EpisodeType | EpisodeType" but the view/DTO's have mixed types

  resumeTime: number;

  progress: number;

  constructor(mediaContent: T) {
    this.mediaContent = mediaContent;
    this.progress = 0;
    this.resumeTime = 0;
  }

  /**
   * Sets the resumeTime value for the Bookmark
   * @param {number} resumeTime value for the resume time to be stored
   * @returns {BookmarkBuilder<T>} "this" - Current BookmarkBuilder instance.
   */
  setResumeTime(resumeTime: number) {
    this.resumeTime = resumeTime;
    return this;
  }

  /**
   * Sets the progress value for the Bookmark
   * @param {number} progress value the progress percentage
   * @returns {BookmarkBuilder<T>} "this" - Current BookmarkBuilder instance.
   */
  setProgress(progress: number) {
    this.progress = progress;
    return this;
  }

  /**
   * Return/create the final MediaContent object.
   * @returns {MediaContent} "this" - Current BookmarkBuilder instance.
   */
  create(): EpisodeBookmark | MovieBookmark {
    const newMediaContent: T = {
      ...this.mediaContent,
      resumeTime: this.resumeTime,
      progress: this.progress,
      type: 'bookmark',
      assetType:
        this.mediaContent.episodeNumber !== undefined ? 'episode' : 'movie'
    };
    return newMediaContent as EpisodeBookmark | MovieBookmark;
  }
}

export default BookmarkBuilder;
