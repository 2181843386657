/**
 * @module services/page
 * @description
 * Service to handle the fetch of menu items
 */
import cms from '#/providers/cms';
import pageModel from '#/models/page';
import { PROVIDER_TYPE } from '#/config/constants';
import { getSegmentationCachePart } from '#/utils/segmentationUtils';

import fetcher from '../../helpers/fetcher';

/**
 * Gets the content/layout of an specific Page of the application
 * @param {*} params Params defined as an object
 * @param {string} params.route page route path
 * @param {string} params.locale locale code
 * @param {string} params.segmentationValue segmentation value
 * @returns {Promise<Page>} Page
 */
const getPageLayout = async ({ route, locale, segmentationValue }) => {
  const segmentationCachePart = getSegmentationCachePart(segmentationValue);
  const localeCachePart = locale ? `-${locale}` : '';
  const routeCachePart = route.replaceAll('/', '_');
  const cacheId = `${PROVIDER_TYPE.cms}${routeCachePart}${segmentationCachePart}${localeCachePart}`;
  const rawPage = await fetcher({
    cacheId,
    fetchFn: () =>
      cms.getPageLayout({
        route,
        locale,
        gid: segmentationValue
      })
  });

  const page = pageModel(rawPage);

  return page;
};

export { getPageLayout };
