import React from 'react';
import useI18n from '#/hooks/useI18n';
import useMenu from '#/hooks/useMenu';
import styles from './skeletons.scss';

const PageSkeleton = () => {
  const { currentLocale = {} } = useI18n();
  useMenu({ menuVisible: false });
  const { dir } = currentLocale;
  const baseColorStyle = styles.baseColor;
  return (
    <div
      dir={dir}
      className={`${styles.pageSkeleton} ${baseColorStyle} ${styles[dir]}`}
    >
      <div dir={dir} className={`${styles.menuSkeleton} ${styles[dir]}`} />
      <div dir={dir} className={`${styles.basePageSkeleton} ${styles[dir]}`} />
    </div>
  );
};

export default PageSkeleton;
