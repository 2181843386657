import ovp from '#/providers/ovp';
import { assetTypes } from '#/models/assetTypes';
import EpgContentBlockModelBuilder from '#/models/live/EpgContentBlock';
import LiveProgramBuilder from '#/models/live/liveProgram';
import fetcher from '../../helpers/fetcher';

const CHANNEL_COUNT = 20;

/**
 * @module services/ovp
 * @description
 * Service to handle the fetch of ovp live data
 */

/**
 * Returns a set of listings for the specified time and combination of count+offset
 *
 * @param {Object} params Params object
 * @param {number} params.startTime timestamp value for the initial time to fetch the data for the listings
 * @param {number} params.endTime timestamp value for the final time to fetch the data for the listings
 * @param {number} params.count the number of channels to return the data from
 * @param {number} params.offset the initial position of the channel to start returning data from
 * @param {string} [params.segmentationValue] optional segment to get a different value based on config
 *
 * @returns {Promise<any>} the listings for the specified params
 */
const getTvListings = async ({
  startTime,
  endTime,
  count,
  offset,
  segmentationValue
}) => {
  const data = await fetcher({
    fetchFn: () =>
      ovp.getTvListings({
        startTime,
        endTime,
        count,
        offset,
        segmentationValue
      })
  });
  return new EpgContentBlockModelBuilder(data).create();
};

/**
 * Retrieves a pretty version of hours and minutes information
 * from a timestamp. (HH:MM)
 * @param {Number} unixTimestamp The unix timestamp to get the value from
 * @returns {String} The hours and minutes in the format HH:SS
 */
const getTimeHoursMins = unixTimestamp => {
  const date = new Date(unixTimestamp);
  const hours = `0${date.getHours()}`;
  const minutes = `0${date.getMinutes()}`;

  return `${hours.slice(-2)}:${minutes.slice(-2)}`;
};

/**
 * Returns the information for live data between star time and end time.
 * @param {Object} params Params object
 * @param {Number} params.startTime The start time to fetch information (unix timestamp in ms)
 * @param {Number} params.endTime The end time to fetch information (unix timestamp in ms)
 * @param {string} [params.segmentationValue] optional segment to get a different value based on config
 * @returns {Promise<Object[]>} Live programs
 */
const getLiveData = async ({ startTime, endTime, segmentationValue } = {}) => {
  const channels = await fetcher({
    fetchFn: () => ovp.getChannelData(segmentationValue)
  });
  const count = channels.totalCount || CHANNEL_COUNT;
  const data = await getTvListings({
    startTime,
    endTime,
    count,
    segmentationValue
  });

  return data.entries.map(record => {
    const { programs, images } = record;
    const channel = images && images.length > 0 ? images[0] : null;
    const curProgram = programs.length > 0 ? programs[0] : null;
    const nextProgram = programs.length > 1 ? programs[1] : null;

    const curStartTime = getTimeHoursMins(curProgram?.startTime);
    const curEndTime = getTimeHoursMins(curProgram?.endTime);
    const nextStartTime = getTimeHoursMins(nextProgram?.startTime);
    const nextEndTime = getTimeHoursMins(nextProgram?.endTime);

    return {
      id: record.id,
      type: assetTypes.LIVE_CHANNEL,
      channel,

      curProgram,
      nextProgram,

      curTitle: curProgram?.title || '',
      curStartTime,
      curEndTime,

      nextTitle: nextProgram?.title || '',
      nextStartTime,
      nextEndTime
    };
  });
};

/**
 * Returns the program data associated with a channel for a certain time
 *
 * @param {Object} params params object
 * @param {string} params.channel channel identifier
 * @param {number} params.startTime The start time to fetch information (unix timestamp in ms)
 * @param {string} [params.segmentationValue] optional segment to get a different value based on config
 * @returns {Promise<any>} program data
 */
const getProgramData = async ({ channel, startTime, segmentationValue }) => {
  const tvListingsData = await ovp.getChannelTvListings({
    channel,
    startTime,
    segmentationValue
  });

  const liveProgram = new LiveProgramBuilder(tvListingsData);

  return liveProgram.create();
};

export { getLiveData, getTvListings, getProgramData };
