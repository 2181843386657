import React, { useState, createContext, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

const PageContext = createContext();

const PageProvider = ({ children }) => {
  const [pageInfo, setPageInfo] = useState({
    currentPageId: null,
    previousPageId: null,
    pageLatestState: {} // pageLatestState.pageId = { focusedElement, ... }
  });

  const updateCurrentPageInfo = useCallback(({ currentPageId, newState }) => {
    setPageInfo(d => ({
      ...d,
      previousPageId: d.currentPageId,
      currentPageId,
      pageLatestState: {
        ...d.pageLatestState,
        ...{ [currentPageId]: newState }
      }
    }));
  }, []);

  const updateCurrentPageId = useCallback(newPageId => {
    setPageInfo(d => ({
      ...d,
      previousPageId: d.currentPageId,
      currentPageId: newPageId
    }));
  }, []);

  /**
   * Gets the page info object
   *
   * @returns {Object} The pageInfo object.
   * @returns {string} pageInfo.currentPageId the id of the current rendered page.
   * @returns {string} pageInfo.previousPageId the id of the previous rendered page.
   * @returns {object} pageInfo.pageInfoFilter the id of the previous rendered page.
   */
  const getCurrentPageInfo = useCallback(() => {
    return pageInfo;
  }, [pageInfo]);

  const pageContextValue = useMemo(
    () => ({
      getCurrentPageInfo,
      updateCurrentPageInfo,
      updateCurrentPageId
    }),
    [getCurrentPageInfo, updateCurrentPageInfo, updateCurrentPageId]
  );

  return (
    <PageContext.Provider value={pageContextValue}>
      {children}
    </PageContext.Provider>
  );
};

PageProvider.propTypes = {
  children: PropTypes.node
};

export { PageContext, PageProvider };
