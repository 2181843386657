import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';

import FailedToLoad from '#/views/FailedToLoad/FailedToLoad';
import ContainerPage from '#/views/ContainerPage/ContainerPage';
import { PageInfoPropTypes } from '#/containers/PageLayout/PageLayout';

import useAppError from '#/hooks/useAppError';

const ModularPage = ({ pageInfo, ...props }) => {
  const { state } = useLocation();
  console.log(`[debug] state: `, state);
  const { addError, removeError } = useAppError();

  const pageProps = useMemo(
    () => ({
      id: pageInfo.id
    }),
    [pageInfo]
  );

  useEffect(() => {
    if (pageInfo.failedToLoad) {
      addError();
    } else {
      removeError();
    }
  }, [pageInfo.failedToLoad, addError, removeError]);

  if (pageInfo.failedToLoad) {
    return <FailedToLoad debugInfo={pageInfo.errorMessage} />;
  }

  if (!pageInfo.loaded) {
    return null;
  }

  return (
    <ContainerPage
      containers={pageInfo.containers}
      contextData={state}
      pageProps={pageProps}
      displayText={pageInfo.displayText}
      key={pageInfo.id}
      {...props}
    />
  );
};

ModularPage.propTypes = {
  pageInfo: PageInfoPropTypes.isRequired,
  containers: PropTypes.array,
  dispatch: PropTypes.func,
  displayText: PropTypes.string,
  errorMessage: PropTypes.string,
  failedToLoad: PropTypes.bool,
  loaded: PropTypes.bool,
  template: PropTypes.string
};

export default ModularPage;
