import React from 'react';
import useI18n from '#/hooks/useI18n';
import useMenu from '#/hooks/useMenu';
import styles from './skeletons.scss';

// SearchSkeletons returns a list of empty suggestions when users go to search page.
const SearchSkeleton = () => {
  const { currentLocale = {} } = useI18n();
  useMenu({ menuVisible: false });
  const { dir } = currentLocale;
  const ROWS = 6;
  const baseColorStyle = styles.baseColor;
  return (
    <div dir={dir} className={styles.searchSkeleton}>
      <div
        dir={dir}
        className={`${styles.searchKeyboard} ${baseColorStyle} ${styles[dir]}`}
      />
      <div dir={dir} className={`${styles.searchTrends} ${styles[dir]}`}>
        {[...Array(ROWS)].map((item, index) => (
          <div
            key={`searchItem${index}${item}`}
            className={`${styles.searchSuggestion} ${baseColorStyle} ${styles[dir]}`}
          />
        ))}
      </div>
    </div>
  );
};

export default SearchSkeleton;
