import { useContext } from 'react';
import { AppErrorContext } from '#/context/AppErrorContext';

function useAppError() {
  const {
    error,
    errorTitleKey,
    errorMessageKey,
    errorAcceptText,
    addError,
    removeError
  } = useContext(AppErrorContext);
  return {
    error,
    errorTitleKey,
    errorMessageKey,
    errorAcceptText,
    addError,
    removeError
  };
}

export default useAppError;
