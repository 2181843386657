/**
 * @module providers/i18n/control
 */
import { getApplicationConfiguration } from '#/services/config';
import { getControlClientSingleton } from '#/providers/shared/control/control';
import { getDictionaryEntryId } from '#/providers/shared/control/config';
import { DEFAULT_LOCALE } from '#/config/constants';
import localProvider from '../local/local';

let accedoOneClientInstance = null;
let initiated = false;
let segmentInitiated = null;

export const init = async segmentationValue => {
  accedoOneClientInstance = await getControlClientSingleton(segmentationValue);
  segmentInitiated = segmentationValue;
  initiated = true;
};

/**
 * Returns the default locale code of the application, when no selected
 * @param {string} [segmentationValue] optional segment to get a different config
 * @returns {Promise<String>} default locale code
 */
const getDefaultLocaleCode = async segmentationValue => {
  try {
    const applicationConfiguration = await getApplicationConfiguration(
      segmentationValue
    );
    const {
      i18n: { defaultLocale = DEFAULT_LOCALE } = {}
    } = applicationConfiguration;
    return defaultLocale || DEFAULT_LOCALE;
  } catch (e) {
    console.warn(
      `Unable to getDefaultLocaleCode from Control, returning default. Error: ${e}`
    );
    return DEFAULT_LOCALE;
  }
};

/**
 * Returns all the available dictionaries/locales of the application
 * @param {string} [segmentationValue] optional segment to get a different config
 *
 * @returns {Promise<String[]>} All the application local codes
 */
const getLocales = async segmentationValue => {
  let localesResponse = {};
  try {
    if (
      !initiated ||
      (segmentationValue && segmentInitiated !== segmentationValue)
    ) {
      await init(segmentationValue);
    }

    localesResponse = await accedoOneClientInstance.getAvailableLocales();
  } catch (e) {
    console.warn(
      `Unable to get Locales from Control, returning default. Error: ${e}`
    );
  }
  return localesResponse?.locales || [];
};

/**
 * Returns an specific dictionary based on a locale code
 *
 * @param {String} localeCode locale code to get the Locale/Dictionary from
 * @param {string} [segmentationValue] optional segment to get a different config
 *
 * @returns {Promise<Object>} locale associated
 */
const getLocale = async (localeCode, segmentationValue) => {
  let dictionary;
  let dictionaryEntry = {};
  try {
    dictionary = await getDictionaryEntryId(segmentationValue);
  } catch (e) {
    console.warn(
      `Unable to get configuration from Control, returning default. Error: ${e}`
    );
  }
  try {
    if (!initiated) {
      await init();
    }

    dictionaryEntry = await accedoOneClientInstance.getEntryById(dictionary, {
      locale: localeCode
    });
  } catch (e) {
    console.warn(
      `Unable to get Dictionary from Control, returning from local. Error: ${e}`
    );
    dictionaryEntry = await localProvider.getLocale(localeCode);
  }
  return dictionaryEntry;
};
export default {
  getDefaultLocaleCode,
  getLocales,
  getLocale
};
