export enum NOTIFICATION_TYPES {
  success = 'success',
  error = 'error',
  portrait = 'portrait',
  landscape = 'landscape',
  square = 'square'
}

export const SHOW_NOTIFICATION_ANIMATION_TIME = 0.5; // seconds
export const HIDE_NOTIFICATION_ANIMATION_TIME = 0.5; // seconds

export enum NOTIFICATION_POSITIONS {
  topstart = 'topstart',
  topend = 'topend',
  bottomstart = 'bottomstart',
  bottomend = 'bottomend'
}

export enum NOTIFICATION_GROW_VALUES {
  up = 'up',
  down = 'down'
}

export interface Notification {
  type: NOTIFICATION_TYPES;
  position?: NOTIFICATION_POSITIONS;
  title: string;
  description: string;
  image?: string;
  timer?: number;
  isNew?: boolean;
  toHide?: boolean;
}

export interface NotificationExtendedType extends Notification {
  id: string;
}
