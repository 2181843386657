/**
 * @module models/CMS
 */
/**
 * This model represents the Menu.
 *
 * vikiElevateMenu representation
 * @typedef {Object} VikiElevateMenu
 * @property {String} title title (on CMS) property
 * @property {String} displaytext menu text property
 * @property {VikimapMenuItem[]} items Menu items
 */

import menuItem from './menuItem';

/**
 * VikiElevateMenu Model
 * @param {Object} rawMenu Menu
 * @returns {VikiElevateMenu} menu - The {@link VikiElevateMenu} representation
 */
const menu = rawMenu => {
  const { title, displaytext, items = [] } = rawMenu;
  /**
   * Get the item's CMS title
   * @returns {String} title
   */
  const getTitle = () => {
    return title;
  };
  /**
   * Get the item's Display Text
   * @returns {String} display text
   */
  const getDisplayText = () => {
    return displaytext;
  };
  /**
   * Get the item's displayText
   * @returns {String} displaytext
   */
  const getItems = () => {
    return items.map(item => menuItem(item));
  };

  return {
    title: getTitle(),
    displayText: getDisplayText(),
    items: getItems()
  };
};

export default menu;
