/**
 * @module services/query
 * @description
 * Service to fetch data to the OVP using queries
 */
import ovp from '#/providers/ovp';
import Item from '#/models/item/item';
import { PROVIDER_TYPE } from '#/config/constants';

import interpolateTextWithObject from '#/utils/interpolateTextWithObject';
import { getSegmentationCachePart } from '#/utils/segmentationUtils';

import fetcher from '../../helpers/fetcher';

/**
 * @param {String} query The query for the OVP.
 * @param {String} contextData Context for being interpolated with the query.
 * @param {Number} from Init index for retriving data.
 * @param {Number} to Final index for retriving data.
 * @param {Number} itemsPerPage Max number of item per query.
 * @param {String} sortBy Key for ordering data.
 * @param {string} [segmentationValue] optional segment to get a different config
 * @returns {Promise<Object>} Array of Items
 */

const getItemsByQuery = async ({
  query,
  pageNumber,
  contextData,
  itemsPerPage,
  sortBy = '',
  segmentationValue
}) => {
  const segmentationValueCacheValue = getSegmentationCachePart(
    segmentationValue
  );
  const interpolatedQuery = interpolateTextWithObject(query, contextData);

  const result = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-query-${interpolatedQuery}-${pageNumber}${segmentationValueCacheValue}`,
    fetchFn: () =>
      ovp.getItemsByQuery({
        query: interpolatedQuery,
        pageNumber,
        sortBy,
        itemsPerPage,
        segmentationValue
      })
  });

  if (!result) {
    console.warn(`[debug] Not response from query: ${query}`);
  }

  return {
    items: result?.items?.map(item => Item(item)),
    total: result?.total
  };
};

export { getItemsByQuery };
