import { useContext } from 'react';
import { NotificationSystemContext } from '#/context/NotificationSystemContext';

function useNotificationSystem() {
  const {
    addNotification,
    removeNotification,
    hideNotification,
    notifications
  } = useContext(NotificationSystemContext);

  return {
    addNotification,
    removeNotification,
    hideNotification,
    notifications
  };
}

export default useNotificationSystem;
