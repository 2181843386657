/**
 * @module services/movie
 * @description
 * Service to handle the fetch of movies
 */
import ovp from '#/providers/ovp';
import Movie from '#/models/movie/movie';
import { Movie as iMovie } from '#/interfaces/Movie';
import { PROVIDER_TYPE } from '#/config/constants';
import { getSegmentationCachePart } from '#/utils/segmentationUtils';

import fetcher from '../../helpers/fetcher';

/**
 * gets Movie from a provider identifier
 * @param {string} id movie identifier
 * @param {string} [segmentationValue] optional segment to get a different config
 * @returns {Promise<iMovie>} movie
 */
const getMovie = async (id, segmentationValue) => {
  const segmentationValueCacheValue = getSegmentationCachePart(
    segmentationValue
  );
  const rawMovie = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-movie-${id}${segmentationValueCacheValue}`,
    fetchFn: () => ovp.getMovieById(id, segmentationValue)
  });
  return Movie(rawMovie);
};

export { getMovie };
