import React, { useState, useCallback, useMemo, createContext } from 'react';
import PropTypes from 'prop-types';

export const AppErrorContext = createContext({
  error: null,
  errorTitleKey: 'loadErrorTitle',
  errorMessageKey: 'loadErrorMessage',
  errorAcceptText: null,
  addError: () => {},
  removeError: () => {}
});

const AppErrorProvider = ({ children }) => {
  const [error, setError] = useState(false);
  const [errorTitleKey, setErrorTitleKey] = useState('loadErrorTitle');
  const [errorMessageKey, setErrorMessageKey] = useState('loadErrorMessage');
  const [errorAcceptText, setErrorAcceptText] = useState();

  const removeError = useCallback(() => {
    setError(false);
  }, []);

  const addError = useCallback(
    ({
      errorTitleKey: errorTitleKeyFromError = 'loadErrorTitle',
      errorMessageKey: errorMessageKeyFromError = 'loadErrorMessage',
      errorAcceptText: errorAcceptTextFromError
    } = {}) => {
      setError(true);
      setErrorTitleKey(errorTitleKeyFromError);
      setErrorMessageKey(errorMessageKeyFromError);
      setErrorAcceptText(errorAcceptTextFromError);
    },
    []
  );

  const contextValue = useMemo(
    () => ({
      error,
      errorTitleKey,
      errorMessageKey,
      errorAcceptText,
      addError,
      removeError
    }),
    [
      error,
      errorTitleKey,
      errorMessageKey,
      errorAcceptText,
      addError,
      removeError
    ]
  );

  return (
    <AppErrorContext.Provider value={contextValue}>
      {children}
    </AppErrorContext.Provider>
  );
};

AppErrorProvider.propTypes = {
  children: PropTypes.node
};

export default AppErrorProvider;
