import { ConfigInput } from '#/interfaces/ConfigInput';
import { Config } from '#/interfaces/Config';
/**
 * @module models/CMS
 */

/**
 * VikiElevateConfig Model
 * @param {Object} rawConfig Config
 * @returns {VikiElevateConfig} config - The {@link VikiElevateConfig} representation
 */
const config = (rawConfig: ConfigInput): Config => {
  const {
    application = {} as any,
    providers = {} as any,
    featureFlags = {} as any,
    status,
    assets
  } = rawConfig;

  /**
   * Get the app status
   * @returns {String} status
   */
  const getStatus = (): string | undefined => {
    return status;
  };

  /**
   * Get the app status
   * @returns {String} status
   */
  const getAppLogo = (): string | undefined => {
    return assets?.appLogo;
  };

  /**
   * Get the Feature Flags
   * @returns {object} featureFlags
   */
  const getApplicationConfiguration = (): any => {
    return application;
  };

  /**
   * Get the Feature Flags
   * @returns {object} featureFlags
   */
  const getFeatureFlags = (): any => {
    return featureFlags;
  };

  /**
   * Get the Feature Flags
   * @returns {object} featureFlags
   */
  const getProvidersConfiguration = (): any => {
    return providers;
  };

  return {
    ...rawConfig,
    status: getStatus(),
    appLogo: getAppLogo(),
    featureFlags: getFeatureFlags(),
    providers: getProvidersConfiguration(),
    application: getApplicationConfiguration()
  };
};

export default config;
