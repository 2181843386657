const getValuebyNestedStringKey = (object, nestedKey) => {
  nestedKey = nestedKey.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  nestedKey = nestedKey.replace(/^\./, ''); // strip a leading dot
  const a = nestedKey.split('.');
  // eslint-disable-next-line no-plusplus
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (k in object) {
      object = object[k];
    } else {
      return;
    }
  }
  return object;
};

/**
 * Interpolate a String with {{keys}} with the passed object
 * @param {String} text with {{keys}} to be interpolated
 * @param {Object} object key/value objects to be interpolated in the text
 * @return {String} Interpolated String
 */
export default (text = '', object = {}) => {
  const parameters = text?.match(/[^{}]+(?=})/g);
  let interpolatedText = text;

  parameters?.forEach(parameter => {
    if (parameter && object) {
      const value = getValuebyNestedStringKey(object, parameter);

      if (!value) {
        return;
      }

      interpolatedText = interpolatedText.replace(
        new RegExp(`{{${parameter}}}`),
        value
      );
    }
  });

  return interpolatedText;
};
