import { Collection } from '#/interfaces/Collection';

const CollectionModel = (rawCollection: any): Collection => {
  const {
    id,
    query,
    name,
    description,
    role,
    backgroundTemplate,
    pictureTemplate
  } = rawCollection;

  const getId = (): string => {
    return id;
  };
  const getQuery = (): string => {
    return query;
  };
  const getPicture = (): string => {
    return pictureTemplate;
  };
  const getBackground = (): string => {
    return backgroundTemplate || '';
  };
  const getName = (): string => {
    return name;
  };
  const getDescription = (): string => {
    return description;
  };
  const getRole = (): string => {
    return role;
  };

  return {
    id: getId(),
    query: getQuery(),
    picture: getPicture(),
    backgroundUrl: getBackground(),
    name: getName(),
    description: getDescription(),
    role: getRole()
  };
};

class CollectionModelBuilder<T> {
  rawCollection: T;

  constructor(rawCollection: T) {
    this.rawCollection = rawCollection;
  }

  /**
   * Return/create the final Info object.
   * @returns {Info} "this" - Current Info instance.
   */
  create(): Collection {
    return CollectionModel(this.rawCollection);
  }
}

export { CollectionModelBuilder, CollectionModel };
