import { mapToArray } from '#/utils/dataConverterHelper';
import {
  getBookmark as getBookmarkFromProvider,
  getBookmarks as getBookmarksFromProvider,
  saveBookmark as saveBookmarkFromProvider,
  getContinueWatchingItems as getContinueWatchingItemsFromProvider,
  getNextEpisodeToPlayForShow as getNextEpisodeToPlayForShowFromProvider,
  getBookmarksForShow as getBookmarksForShowFromProvider,
  getMoviePlayStatusBookmark as getMoviePlayStatusBookmarkFromProvider
} from '#/providers/bookmark';
import Bookmark from '#/models/Bookmark/bookmark';

const bookmarkService = profileId => {
  /**
   * Stores a bookmark on the watch history
   *
   * @param {BookmarkMovie | BookmarkEpisode} bookmarkItem The item to be stored
   * @returns {Promise<boolean>} Promise with a flag that indicates the success for adding an entry to the Bookmark storage
   */
  const saveBookmark = async bookmarkItem =>
    saveBookmarkFromProvider(profileId, bookmarkItem);

  /**
   * Get a Bookmark item from the store.
   * @example
   * await getBookmark(contentItem)
   * @param {Object} entry The specific entry, it should contain and id
   * @returns {Promise<Bookmark|undefined>} Promise with the associated Bookmark or undefined
   */
  const getBookmark = async entry => {
    try {
      const bookmark = await getBookmarkFromProvider(profileId, entry);
      if (!bookmark) {
        return;
      }
      return Bookmark(bookmark);
    } catch (error) {
      console.error(`[error] Error on getBookmark service: `, error);
    }
  };

  /**
   * Get Bookmark items from the store.
   * @example
   * await getBookmarks()
   * @returns {Promise<Array<Bookmark>>} Promise with all Bookmark storage items
   */
  const getBookmarks = async () => {
    const unparsedBookmarksMap = await getBookmarksFromProvider(profileId);
    const unparsedBookmarks = mapToArray(unparsedBookmarksMap);
    return unparsedBookmarks.map(bookmark => Bookmark(bookmark));
  };

  /**
   * Get Bookmark items from the store for a Show.
   * @example
   * await getBookmarksForShow('testShowId')
   * @param {String} showId - The id for the show.
   * @returns {Promise<Array<Bookmark>>} Promise with all Bookmark storage items for the given Show
   */
  const getBookmarksForShow = async showId => {
    return getBookmarksForShowFromProvider(profileId, showId);
  };

  /**
   * Gets the onprogress movies and the latest episode for each shwho
   * @returns {Promise<Bookmark[]>} - The movies and episode items
   */
  const getContinueWatchingItems = async () => {
    const continueWatchingItems =
      (await getContinueWatchingItemsFromProvider(profileId)) || new Map();
    const continueWatchingItemsA = [];
    // From Map to pure Array so index and lenght is fixed and can be handled by MyContent page
    continueWatchingItems.forEach(item => continueWatchingItemsA.push(item));
    return continueWatchingItemsA;
  };

  /**
   * Return the episode to start/continue watching for an specified show.
   * @param {string} showId - The id for the show.
   * @param {string} [segmentationValue] - optional segment to get a different config
   * @returns {Promise<Bookmark>} - The Episode to watch.
   */
  const getNextEpisodeToPlayForShow = async (showId, segmentationValue) => {
    return getNextEpisodeToPlayForShowFromProvider({
      profileId,
      showId,
      segmentationValue
    });
  };

  /**
   * Return the movie bookmark status, set progress to 0 when reached certain progress to reset.
   * @param {String} movieId - The id for the movie.
   * @returns {Promise<Bookmark>} - The Movie to watch in the poper progress.
   */
  const getMoviePlayStatusBookmark = async movieId => {
    return getMoviePlayStatusBookmarkFromProvider(profileId, {
      id: movieId
    });
  };

  return {
    saveBookmark,
    getBookmark,
    getBookmarks,
    getMoviePlayStatusBookmark,
    getNextEpisodeToPlayForShow,
    getBookmarksForShow,
    getContinueWatchingItems
  };
};

export default bookmarkService;
