import { useState, useEffect, useCallback, useMemo } from 'react';
import { bookmarkService } from '#/services/userData';
import BookmarkBuilder from '#/models/Bookmark/bookmarkBuilder';

/**
 * @param params {Object} React ref to avoid re-definitions of callbacks
 * @param params.dataRef {Object} React ref to avoid re-definitions of callbacks
 * @param params.isAuthenticated {boolean} React ref to avoid re-definitions of callbacks
 * @param params.userName {string} React ref to avoid re-definitions of callbacks
 * @returns {Object} userBookmarksObject
 * @returns {Array} userBookmarksObject.userBookmarks
 * @returns {Function} userBookmarksObject.getUserBookmarks
 * @returns {Function} userBookmarksObject.saveBookmark
 * @returns {Function} userBookmarksObject.removeLatestEpisode
 * @returns {Function} userBookmarksObject.getBookmark
 */

const useBookmarks = ({ dataRef, isAuthenticated, profileId }) => {
  const {
    getContinueWatchingItems,
    getBookmark: getBookmarkFn,
    saveBookmark: saveBookmarkFn
  } = useMemo(() => bookmarkService(profileId), [profileId]);

  const [userBookmarks, setUserBookmarks] = useState();

  const getUserBookmarks = useCallback(async () => {
    let bookmarks = [];
    if (isAuthenticated) {
      bookmarks = await getContinueWatchingItems();
    }

    return bookmarks;
  }, [isAuthenticated, getContinueWatchingItems]);

  const saveBookmark = useCallback(async () => {
    if (!isAuthenticated || !dataRef) {
      return [];
    }
    const { progress, duration, asset } = dataRef.current;

    const progressPercentage = (progress * 100) / duration;

    const bookmark = new BookmarkBuilder(asset)
      .setResumeTime(progress)
      .setProgress(progressPercentage)
      .create();

    saveBookmarkFn(bookmark);
  }, [dataRef, isAuthenticated, saveBookmarkFn]);

  const getBookmark = useCallback(
    ({ id: assetId }) => {
      if (!isAuthenticated) {
        return null;
      }
      return getBookmarkFn(assetId);
    },
    [isAuthenticated, getBookmarkFn]
  );

  useEffect(() => {
    let componentIsMounted = true;

    getUserBookmarks().then(data => {
      if (componentIsMounted) {
        setUserBookmarks(data);
      }
    });

    return () => {
      componentIsMounted = false;
    };
  }, [isAuthenticated, getUserBookmarks]);

  return {
    userBookmarks,
    getUserBookmarks,
    saveBookmark,
    getBookmark
  };
};

export default useBookmarks;
