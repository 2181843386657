/**
 * @module services/menu
 * @description
 * Service to handle the fetch of menu items
 */
import cms from '#/providers/cms';
import menuModel from '#/models/menu';
import { PROVIDER_TYPE } from '#/config/constants';
import { getSegmentationCachePart } from '#/utils/segmentationUtils';

import fetcher from '../../helpers/fetcher';

/**
 * Gets the Menu for the application
 * @param {any} params Params defined as an object
 * @param {boolean} params.isAuthenticated whether the user is authenticated in the application or not, to display the proper items
 * @param {string} params.locale locale code
 * @returns {Promise<Menu>} Menu Items
 */
const getMenu = async ({ isAuthenticated, locale, segmentationValue }) => {
  const localeCachePart = locale ? `-${locale}` : '';
  const segmentationCachePart = getSegmentationCachePart(segmentationValue);
  const cacheId = `${PROVIDER_TYPE.cms}-menu${localeCachePart}${segmentationCachePart}`;
  const rawMenu = await fetcher({
    cacheId,
    fetchFn: () => cms.getMenu({ locale, gid: segmentationValue })
  });
  const menu = menuModel(rawMenu);

  // return only usable items according to the user state
  const menuItems = menu.items.filter(
    item =>
      !(item.hiddenWhenAuth && isAuthenticated) &&
      !(item.requireAuthentication && !isAuthenticated)
  );

  const primaryItems = [];
  const secondaryItems = [];
  menuItems.forEach(item => {
    /** The default itemGroup is the 'primary' group */
    item.itemGroup !== 'secondary'
      ? primaryItems.push(item)
      : secondaryItems.push(item);
  });

  return { primaryItems, secondaryItems, title: menu.displayText };
};

export { getMenu };
