import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';

import GridSkeleton from './GridSkeleton';
import DetailSkeleton from './DetailSkeleton';
import SearchSkeleton from './SearchSkeleton';
import PageSkeleton from './PageSkeleton';

const LocalSkeleton = ({
  gridSkeleton, // format is like `{itemType: string(item_type.landscape || item_type.portrait), withTitle: bool, withSpacing: bool}`
  detailSkeleton, // format is like `{itemType: string(item_type.landscape || item_type.portrait)}`
  pageSkeleton, // bool
  searchSkeleton // bool
}) => {
  return (
    <>
      {detailSkeleton && <DetailSkeleton itemType={detailSkeleton.itemType} />}
      {searchSkeleton && <SearchSkeleton />}
      {pageSkeleton && <PageSkeleton />}
      {gridSkeleton && !searchSkeleton && <GridSkeleton {...gridSkeleton} />}
    </>
  );
};

LocalSkeleton.propTypes = {
  gridSkeleton: PropTypes.object,
  detailSkeleton: PropTypes.object,
  pageSkeleton: PropTypes.bool,
  searchSkeleton: PropTypes.bool
};

const Skeleton = React.memo(LocalSkeleton, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps)
);
Skeleton.item_type = GridSkeleton.item_type;

export default Skeleton;
