import React from 'react';

import { NOTIFICATION_TYPES } from '#/interfaces/Notification';

import successImg from '#/static/images/success.png';
import errorImg from '#/static/images/error.png';
import defaultSrc from '#/static/images/elevate_color_negative.svg';
import theme from './snackbar.scss';

const HARDCODED_IMAGES = {
  success: successImg,
  error: errorImg
};
export interface SnackBarType {
  id: string;
  description: string;
  title: string;
  type: NOTIFICATION_TYPES;
  toHide?: boolean;
  isNew?: boolean;
  image?: string;
}

export interface SnackbarProps extends SnackBarType {
  dir?: 'ltr' | 'rtl';
}

const SnackBar = ({
  id,
  description,
  title,
  type,
  image,
  isNew = false,
  toHide = false,
  dir = 'ltr'
}: SnackbarProps) => {
  const hideClass = toHide ? theme.hideAnimation : '';
  const snackbarClassNames = `${theme.snackbar} ${theme[type]} ${hideClass} ${
    isNew ? theme.new : ''
  } ${theme[dir]}`;
  const hardcodedImageKeys = Object.keys(HARDCODED_IMAGES);
  let imageFromType;
  if (hardcodedImageKeys.includes(type)) {
    imageFromType = HARDCODED_IMAGES[type as 'success' | 'error'];
  }
  const imageToDisplay = image ?? imageFromType ?? defaultSrc;

  return (
    <div id={id} className={snackbarClassNames} dir={dir}>
      <div className={theme.content} dir={dir}>
        <div className={`${theme.imageContainer} ${theme[type]} ${theme[dir]}`}>
          {imageToDisplay && (
            <img
              src={imageToDisplay}
              alt="icon"
              className={`${theme.image} ${theme[type]}`}
            />
          )}
        </div>
        <div className={theme.textContainer} dir={dir}>
          <div className={theme.title} dir={dir}>
            {title}
          </div>
          <div className={theme.text} dir={dir}>
            {description}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SnackBar;
export { NOTIFICATION_TYPES };
