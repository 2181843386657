import { v4 as uuidv4 } from 'uuid';
import { Avatar } from '#/interfaces/Avatar';

const avatarFallback: Avatar = {
  id: '',
  src: ''
};

class AvatarBuilder {
  avatar: Avatar;

  constructor(avatar?: Avatar) {
    if (avatar) {
      this.avatar = avatar;
      return;
    }

    this.avatar = avatarFallback;
  }

  fromRawData(rawData: any) {
    if (!rawData) {
      throw Error('AvatarBuilder - raw data should be valid.');
    }

    const { id, avatar } = rawData;

    const newAvatar: Avatar = {
      id: id || uuidv4(),
      src: avatar
    };

    this.avatar = newAvatar;
    return this;
  }

  create(): Avatar {
    const newAvatar: Avatar = this.avatar;
    return newAvatar;
  }
}

export default AvatarBuilder;
