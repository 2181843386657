/**
 * Helper method to convert a map into an array
 * @param {Map} value A map
 * @returns {Array} An array
 */
const mapToArray = value => Array.from(value.values());

/**
 * Helper method to convert a map into a string
 * @param {Map} value A map
 * @returns {String} A string
 */
const mapToString = value =>
  JSON.stringify(value, (k, v) => (v instanceof Map ? [...v] : v));

/**
 * Helper method to convert a string into a map
 * @param {String|undefined} value A string
 * @returns {Map} A map
 */
const stringToMap = value => {
  if (!value) {
    return new Map();
  }
  const map = new Map(JSON.parse(value));
  map.forEach((v, k) => map.set(k, typeof v === 'string' ? JSON.parse(v) : v));
  return map;
};

export { mapToArray, mapToString, stringToMap };
