import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withFocus } from '@accedo/vdkweb-navigation';

import roundButtonTheme from './roundButton.scss';

// eslint-disable-next-line valid-jsdoc
/**
 * Component that represent a Focusable round-styled Button
 */
const RoundButton = ({
  className,
  onClick,
  theme = roundButtonTheme,
  displayText,
  isFocused,
  isSelected,
  nav = {},
  style,
  Icon = null,
  DetailsButtonIcon = null,
  iconClassName,
  isFocusedCallback
}) => {
  const focused = isFocused || nav.isFocused;

  useEffect(() => {
    isFocusedCallback?.(focused);
  }, [focused]);

  const buttonClassName = classNames(theme.button, {
    [theme.selected]: isSelected,
    [theme.focused]: focused
  });

  return (
    <div
      className={className}
      onMouseOver={nav.onMouseOver}
      onMouseOut={nav.onMouseOut}
    >
      <button onClick={onClick} className={buttonClassName} style={style}>
        {DetailsButtonIcon && <DetailsButtonIcon />}
        <span>
          {displayText} {Icon && <Icon className={iconClassName} />}
        </span>
      </button>
    </div>
  );
};

RoundButton.propTypes = {
  /** Module class to apply component */
  className: PropTypes.string,
  /** Text to display in the button */
  displayText: PropTypes.string,
  /** If focused style should apply */
  isFocused: PropTypes.bool,
  /** If seleted style should apply */
  isSelected: PropTypes.bool,
  /** nav object as defined in @accedo/vdkweb-navigation */
  nav: PropTypes.object,
  /** function to handle action on the item */
  onClick: PropTypes.func,
  /** style object */
  style: PropTypes.object,
  /** theme object */
  theme: PropTypes.object,
  /** Icon/Image to use along with the textin the Button */
  Icon: PropTypes.elementType,
  /** Icon/Image to use along with the textin the Button of details page */
  DetailsButtonIcon: PropTypes.elementType,
  /** Module class to apply to the Icon if applicable */
  iconClassName: PropTypes.string,
  isFocusedCallback: PropTypes.func
};

const FocusRoundButton = withFocus(RoundButton);

export { RoundButton, FocusRoundButton };
