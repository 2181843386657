import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { focusManager } from '@accedo/vdkweb-navigation';

import Popup from '#/components/Popup/Popup';

import { getTranslation } from '#/services/i18n';
import useNetworkStatus from '#/hooks/useNetworkStatus';
import useHistoryPush from '#/hooks/history/useHistoryPush';
import { ROUTES } from '#/config/constants';

const POPUP_ID = 'NETWORK_ERROR_POPUP';

const DEFAULT_RETRY_TIMER = 30;

const appReload = () => {
  // clear browser history
  if (window.history.replaceState) {
    window.history.replaceState(null, '', window.location.origin);
  }
  // reload page, the url only contains origin, no query parameters
  window.location.replace(window.location.origin);
};

const NetworkError = () => {
  const [retrying, setRetrying] = useState(false);
  const { networkStatus } = useNetworkStatus();
  const historyPush = useHistoryPush();
  const { state } = useLocation();
  const retryTimerRef = useRef();

  const [retryTimerValue, updateRetryTimerValue] = useState(
    DEFAULT_RETRY_TIMER
  );

  const handleNetworkReconnection = useCallback(() => {
    // Case for Fallback, no navigation but from route fallback
    if (!state?.redirection) {
      appReload();
    }
    historyPush({
      state: state?.data,
      path: state?.redirection || ROUTES.home,
      skipCurrentOnBack: true
    });
  }, [historyPush, state?.data, state?.redirection]);

  const handleRetry = useCallback(() => {
    setRetrying(true);
    setTimeout(() => {
      setRetrying(false);
      if (networkStatus) {
        handleNetworkReconnection();
      } else {
        updateRetryTimerValue(DEFAULT_RETRY_TIMER);
        console.log(`[debug] Still not able to reconnect`);
      }
    }, 1000);
  }, [handleNetworkReconnection, networkStatus]);

  useEffect(() => {
    if (!retryTimerRef.current) {
      retryTimerRef.current = setTimeout(() => {
        retryTimerRef.current = null;
        if (retryTimerValue > 1) {
          updateRetryTimerValue(oldValue => oldValue - 1);
          return;
        }
        handleRetry();
      }, 1000);
    }
  }, [handleRetry, retryTimerValue]);

  useEffect(() => {
    return () => {
      if (retryTimerRef.current) {
        clearTimeout(retryTimerRef.current);
        retryTimerRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    focusManager.changeFocus(POPUP_ID);
  }, []);
  useEffect(() => {
    if (networkStatus === true) {
      handleNetworkReconnection();
    }
  }, [handleNetworkReconnection, networkStatus]);
  const secondaryMessage = !retrying
    ? getTranslation('reconnectionRetryMessage', { timer: retryTimerValue })
    : getTranslation('retryingMessage');
  return (
    <Popup
      nav={{
        id: POPUP_ID
      }}
      isOpen
      title={getTranslation('networkErrorLoadingViewTitle')}
      content={
        <>
          <div>{getTranslation('networkErrorLoadingViewContent')}</div>
          <div>{secondaryMessage}</div>
        </>
      }
      onAccept={handleRetry}
      acceptText={getTranslation('retry')}
    />
  );
};

export default NetworkError;
