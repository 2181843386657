import React, {
  useState,
  useCallback,
  useMemo,
  createContext,
  ReactNode
} from 'react';
import PropTypes from 'prop-types';

import {
  NOTIFICATION_POSITIONS,
  NotificationExtendedType,
  SHOW_NOTIFICATION_ANIMATION_TIME,
  HIDE_NOTIFICATION_ANIMATION_TIME,
  Notification
} from '#/interfaces/Notification';

interface NotificationContentType {
  notifications: NotificationExtendedType[];
  addNotification: (notification: Notification) => string;
  hideNotification: (id: string) => void;
  removeNotification: (id: string) => void;
}

export const NotificationSystemContext = createContext<NotificationContentType>(
  {
    notifications: [],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addNotification(notification: Notification): string {
      throw new Error('Function not implemented.');
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    hideNotification(id: string): void {
      throw new Error('Function not implemented.');
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    removeNotification(id: string): void {
      throw new Error('Function not implemented.');
    }
  }
);

let currentNotificationIndex = 0;

const NotificationSystemProvider = ({ children }: { children: ReactNode }) => {
  const [notifications, updateNotifications] = useState<
    NotificationExtendedType[]
  >([]);

  const removeisNewFlag = useCallback(
    id => {
      updateNotifications(prevState => {
        const nextState = [] as NotificationExtendedType[];
        prevState.forEach(notification => {
          if (notification.id === id) {
            notification.isNew = false;
          }
          nextState.push(notification);
        });
        return nextState;
      });
    },
    [updateNotifications]
  );

  const addNotification = useCallback(
    notification => {
      const notificationId = `${currentNotificationIndex}`;
      const extendedNotification = {
        id: notificationId,
        type: notification.type,
        position: notification.position || NOTIFICATION_POSITIONS.bottomend,
        title: notification.title,
        image: notification.image,
        isNew: true,
        description: notification.description,
        timer: notification.timer
      } as NotificationExtendedType;
      currentNotificationIndex += 1;
      updateNotifications(prevState => [...prevState, extendedNotification]);
      setTimeout(() => {
        removeisNewFlag(notificationId);
      }, SHOW_NOTIFICATION_ANIMATION_TIME * 1000);
      return notificationId;
    },
    [updateNotifications, removeisNewFlag]
  );

  const removeNotification = useCallback(
    id => {
      updateNotifications(prevState => {
        const nextState = [] as NotificationExtendedType[];
        prevState.forEach(notification => {
          if (notification.id !== id) {
            nextState.push(notification);
          }
        });
        return nextState;
      });
    },
    [updateNotifications]
  );

  const hideNotification = useCallback(
    id => {
      updateNotifications(prevState => {
        const nextState = [] as NotificationExtendedType[];
        prevState.forEach(notification => {
          if (notification.id === id) {
            notification.toHide = true;
          }
          nextState.push(notification);
        });
        return nextState;
      });
      setTimeout(() => {
        removeNotification?.(id);
      }, HIDE_NOTIFICATION_ANIMATION_TIME * 1000);
    },
    [updateNotifications, removeNotification]
  );

  const contextValue: NotificationContentType = useMemo(
    () =>
      ({
        addNotification,
        hideNotification,
        removeNotification,
        notifications
      } as NotificationContentType),
    [addNotification, hideNotification, removeNotification, notifications]
  );

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <NotificationSystemContext.Provider value={contextValue}>
      {children}
    </NotificationSystemContext.Provider>
  );
};

NotificationSystemProvider.propTypes = {
  children: PropTypes.node
};

export default NotificationSystemProvider;
