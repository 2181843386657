import menu from './menu.json';
import page from './page.json';
import theme from './theme.json';
import about from './about.json';
import terms from './terms.json';
import help from './help.json';
import contact from './contact.json';

/**
 * @module providers/cms/local
 * @description
 * Provider CMS implementation
 */

/**
 *
 * Fetch the Menu CMS content
 *
 * @returns {Menu} The Menu
 */
const getMenu = async () => {
  return menu;
};

/**
 *
 * Fetch the Page CMS content
 *
 * @returns {Page} The page
 */
const getPageLayout = async () => {
  return page;
};

/**
 *
 * Fetch the Theme CMS content
 *
 * @returns {Theme} The Theme
 */
const getTheme = async () => {
  return theme;
};

const getAbout = async () => {
  return about;
};
const getTerms = async () => {
  return terms;
};

const getHelp = async () => {
  return help;
};

const getContact = async () => {
  return contact;
};

export default {
  getMenu,
  getPageLayout,
  getTheme,
  getAbout,
  getTerms,
  getHelp,
  getContact
};
