import React, { useCallback, useEffect, useRef, useMemo } from 'react';
import {
  useRoutes,
  useLocation,
  Navigate,
  useSearchParams
} from 'react-router-dom';

import { vKey } from '@accedo/xdk-virtual-key';
import { focusManager } from '@accedo/vdkweb-navigation';
import xdk from '@accedo/xdk-core';

import ErrorPopup from '#/components/ErrorPopup/ErrorPopup';
import NotificationManager from '#/components/NotificationManager/NotificationManager';
import TVHeader from '#/components/Header/TVHeader';
import routes from '#/config/routes';
import { ROUTES, APP_QS_KEY } from '#/config/constants';

import useKeyHandler from '#/hooks/useKeyHandler';
import useHistoryPush from '#/hooks/history/useHistoryPush';
import useHistoryBack from '#/hooks/history/useHistoryBack';
import useGetHistoryStack from '#/hooks/history/useGetHistoryStack';
import useResetStack from '#/hooks/history/useResetStack';
import useAuthProfile from '#/hooks/useAuthProfile';
import useAppConfig from '#/hooks/useAppConfig';

import styles from './app.scss';

const LANDING_PATHS = ['/']; // TODO: dev review this with your CMS config

const shouldRedirectToProfileSelection = !__PROFILE_SELECTION_INITIAL_DISABLED__;

let firstLoad = true;

const isCurrentPathHome = pathname => {
  return LANDING_PATHS.includes(pathname);
};

const PAGES_THAT_OVERWRITES_BACK_EVENT = [
  ROUTES.profileSelection,
  ROUTES.manageProfile,
  ROUTES.createProfile
];

const App = () => {
  const historyPush = useHistoryPush();
  const historyBack = useHistoryBack();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const getHistoryStack = useGetHistoryStack();
  const resetStack = useResetStack();
  const { config = {} } = useAppConfig();
  const { isAuthenticated } = useAuthProfile();
  if (searchParams.size > 0) {
    xdk.storage.set(APP_QS_KEY, searchParams);
  }
  const {
    application: applicationConfiguration = {},
    routes: routesFromConfig
  } = config;
  const { navigation: { defaultRoute } = {} } = applicationConfiguration;
  const routesRef = useRef([]);

  const exitAction = useCallback(() => {
    try {
      xdk.device.system.exit();
    } catch (e) {
      console.warn(e);
    }
  }, []);

  const isHome = useMemo(() => isCurrentPathHome(pathname), [pathname]);

  const shouldExitApplication = useCallback(() => {
    return isHome;
  }, [isHome]);

  const doesThePageOverwriteTheBackEvent = useCallback(() => {
    return !!PAGES_THAT_OVERWRITES_BACK_EVENT.find(page =>
      pathname.includes(page)
    );
  }, [pathname]);

  const keyHandler = useCallback(
    ({ id }, e) => {
      const { UP, DOWN, LEFT, RIGHT, OK, BACK, EXIT } = vKey;
      const KEY_EVENT_PREFIX = 'device:vkey:';
      const isHistoryEmpty = !getHistoryStack().length;
      const currentFocus = focusManager.getCurrentFocus();
      const isArrowButton = currentFocus?.includes?.('arrow');
      let containerId;
      // if the current component handles the pressed key
      // the deafult handler does nothing
      switch (id) {
        case UP.id:
        case DOWN.id:
        case LEFT.id:
        case RIGHT.id: {
          // Carousel Arrow will dissapear when using navigation buttons, we need to reset focus
          if (isArrowButton) {
            [containerId] = currentFocus.split('-');
            focusManager.changeFocus(containerId);
            e.stopImmediatePropagation();
            return;
          }
          // Normal behaviour
          const direction = `next${id.substring(KEY_EVENT_PREFIX.length)}`;
          focusManager.navigateFocus(direction);
          break;
        }
        case OK.id:
          focusManager.click();
          break;
        case BACK.id:
          if (doesThePageOverwriteTheBackEvent()) {
            break;
          } else if (isHistoryEmpty) {
            if (shouldExitApplication()) {
              exitAction();
            } else {
              historyPush({ path: ROUTES.home });
              resetStack();
            }
          } else {
            historyBack();
          }
          break;
        case EXIT.id:
          exitAction();
          break;
        default:
          break;
      }
    },
    [
      getHistoryStack,
      doesThePageOverwriteTheBackEvent,
      exitAction,
      shouldExitApplication,
      historyPush,
      resetStack,
      historyBack
    ]
  );
  useKeyHandler(keyHandler);

  useEffect(() => {
    const appBody = document.getElementsByTagName('body')[0];
    appBody.style.removeProperty('background-color');
  }, []);

  if (routesRef.current.length <= 0) {
    routesRef.current = routes(routesFromConfig);
  }
  const routes_ = useRoutes(routesRef.current);

  if (defaultRoute && firstLoad && isHome) {
    firstLoad = false;
    if (isAuthenticated && shouldRedirectToProfileSelection) {
      return (
        <Navigate
          to={ROUTES.profileSelection}
          state={{ redirection: defaultRoute }}
        />
      );
    }
    return <Navigate to={defaultRoute} />;
  }

  return (
    <div className={styles.viewport}>
      <div className={styles.wrapper}>
        <ErrorPopup />
        <NotificationManager />
        <TVHeader />
        <div className={styles.content}>{routes_}</div>
      </div>
    </div>
  );
};

export default App;
