import { useCallback, useContext } from 'react';
import { AuthContext } from '#/context/AuthContext';
import { ProfileContext } from '#/context/ProfileContext';
import {
  createProfile,
  getProfiles,
  getProfile,
  getAvatars,
  updateProfileAvatar,
  updateProfileName,
  deleteProfile,
  createPIN,
  deletePIN,
  updatePIN
} from '#/services/idp';

const useProfile = () => {
  const {
    currentProfile,
    currentProfileLoading,
    loadCurrentProfile,
    setCurrentProfile,
    clearCurrentProfile
  } = useContext(ProfileContext);
  const {
    login,
    logout,
    getUser,
    isAuthenticated,
    getPairingCode,
    checkAuthStatus
  } = useContext(AuthContext);

  const checkAuthProfileStatus = useCallback(async () => {
    const authUserData = await checkAuthStatus();
    if (!authUserData || authUserData?.error) {
      clearCurrentProfile();
    }
    return authUserData;
  }, [checkAuthStatus, clearCurrentProfile]);

  return {
    currentProfile,
    currentProfileLoading,
    loadCurrentProfile,
    setCurrentProfile,
    clearCurrentProfile,
    login,
    logout,
    getUser,
    isAuthenticated,
    getPairingCode,
    checkAuthStatus: checkAuthProfileStatus,
    createProfile,
    getProfiles,
    getProfile,
    getAvatars,
    updateProfileAvatar,
    updateProfileName,
    deleteProfile,
    createPIN,
    deletePIN,
    updatePIN
  };
};

export default useProfile;
