import React, { useEffect, useState, memo } from 'react';
import { getProtocolRelativePathUrl } from '#/utils/url';
import vw from '#/utils/vw';
import { Config } from '#/interfaces/Config';
import useAppConfig from '#/hooks/useAppConfig';
import style from './imageWithPreload.scss';

const DEFAULT_PLACEHOLDER_IMG_SIZE = 200;

interface ImageCustomStyle {
  height?: string;
  width?: string;
}

interface ImageWithPreloadProps {
  height?: string | number;
  width?: string | number;
  src: string;
  className?: any;
  alt?: string;
  viewAll?: boolean;
  placeholderImgSize?: number;
}

const ImageWithPreload = ({
  src: srcProp,
  className = '',
  alt = '',
  height,
  width,
  viewAll,
  placeholderImgSize
}: ImageWithPreloadProps) => {
  const [src, setSrc] = useState<string | undefined>();
  const [
    containerCustomStyle,
    setContainerCustomStyle
  ] = useState<ImageCustomStyle | null>(null);
  const { config = {} } = useAppConfig();
  const [imageHasBeenLoaded, setImageHasBeenLoaded] = useState(false);

  const handleSizeValue = (value: string | number | undefined) => {
    if (!value) {
      return '100%';
    }

    if (typeof value === 'number') {
      return `${value}px`;
    }

    return value;
  };

  useEffect(() => {
    if (!srcProp) {
      return;
    }

    const customStyleEdit: ImageCustomStyle = {};

    const heightS = handleSizeValue(height);
    customStyleEdit.height = heightS;

    const widthS = handleSizeValue(width);
    customStyleEdit.width = widthS;

    setContainerCustomStyle(customStyleEdit);
    setSrc(getProtocolRelativePathUrl(srcProp));
  }, [srcProp]);

  const placeholder = {
    backgroundImage: !viewAll
      ? `url(${(config as Config)?.appLogo})`
      : 'url("")',
    backgroundSize: placeholderImgSize
      ? vw(placeholderImgSize)
      : vw(DEFAULT_PLACEHOLDER_IMG_SIZE)
  };

  const animationStyle = imageHasBeenLoaded
    ? style.imgLoaded
    : style.imgLoading;

  return (
    <div
      data-testid="imagepreloadcontainer"
      className={`${style.imgContainer} ${style.imgFallback}`}
      style={{ ...placeholder, ...containerCustomStyle }}
    >
      <div className={`${style.imgContent} ${animationStyle}`}>
        {src && (
          <img
            alt={alt}
            src={src}
            className={className}
            onLoad={() => {
              setImageHasBeenLoaded(true);
            }}
          />
        )}
      </div>
    </div>
  );
};

const propsAreEqual = (
  prevProps: ImageWithPreloadProps,
  nextProps: ImageWithPreloadProps
) => {
  const { src: prevSrc } = prevProps || {};
  const { src: nextSrc, viewAll: nextViewAll } = nextProps || {};

  return prevSrc === nextSrc && !nextViewAll;
};

export default memo(ImageWithPreload, propsAreEqual);
