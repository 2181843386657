export const hasClass = ({
  classes,
  newClass
}: {
  classes: string[] | undefined;
  newClass: string;
}) => classes?.includes(newClass) ?? false;

export const addClass = ({
  classes,
  newClass
}: {
  classes: string[] | undefined;
  newClass: string;
}) => {
  const classesSwalowCopy = classes ? [...classes] : [];
  if (!hasClass({ classes: classesSwalowCopy, newClass })) {
    classesSwalowCopy.push(newClass);
  }
  return classesSwalowCopy;
};

export const removeClass = ({
  classes,
  classToRemove
}: {
  classes: string[] | undefined;
  classToRemove: string;
}) => classes?.filter(extraClass => extraClass !== classToRemove) ?? [];
