/**
 * @module services/list
 * @description
 * Service to handle the fetch of a move list
 */

import ovp from '#/providers/ovp';
import { PROVIDER_TYPE } from '#/config/constants';
import Movie from '#/models/movie/movie';
import Show from '#/models/show/show';
import { getSegmentationCachePart } from '#/utils/segmentationUtils';

import fetcher from '../../helpers/fetcher';

/**
 * Get list of movies by category
 * @param {string} category Category id
 * @param {string} [segmentationValue] the value for the segmentation to filter/use for any possible filtering
 * @returns {Promise<Object[]>} Results
 */
const getMoviesByCategory = async (category, segmentationValue) => {
  const segmentationCachePart = getSegmentationCachePart(segmentationValue);
  const rawMovies = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-movies-category-${category}${segmentationCachePart}`,
    fetchFn: () => ovp.getMoviesByCategory({ category, segmentationValue })
  });
  return rawMovies?.map(movie => Movie(movie));
};

/**
 * Get list of tv shows by category
 * @param {string} category Category id
 * @param {string} [segmentationValue] the value for the segmentation to filter/use for any possible filtering
 * @returns {Promise<Object[]>} Results
 */
const getTvShowsByCategory = async (category, segmentationValue) => {
  const segmentationCachePart = getSegmentationCachePart(segmentationValue);
  const rawTvShows = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-shows-category-${category}${segmentationCachePart}`,
    fetchFn: () => ovp.getTvShowsByCategory({ category, segmentationValue })
  });

  return rawTvShows?.map(show => Show(show));
};

export { getMoviesByCategory, getTvShowsByCategory };
