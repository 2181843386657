/**
 * @module services/i18n
 * @description Service to handle internationalization in the application
 */
import i18nProvider from '#/providers/i18n';
import dictionaryModel, { locale as localeModel } from '#/models/dictionary';
import fetcher from '#/services/helpers/fetcher';
import { PROVIDER_TYPE } from '#/config/constants';
import settingsService from '#/services/userData/settings';
import { getSegmentationCachePart } from '#/utils/segmentationUtils';
import polyglot from './polyglot';

/**
 * @typedef LocaleSimple
 * @property {String} code ISO locale code
 * @property {String<'lrt'|'rtl'>} dir directionallity between rtl and ltr
 */

/**
 * Gets the current locale of the application
 *
 * @returns {Promise<LocaleSimple>} locale
 */
const getLocale = async () => {
  return settingsService.getLocale();
};

/**
 * Get all the available locales for the application
 * @param {string} [segmentationValue] optional segment to get a different config
 *
 * @returns {Promise<localeModel[]>} All the available locales
 */
const getLocales = async segmentationValue => {
  const segmentationValueCacheValue = getSegmentationCachePart(
    segmentationValue
  );
  const rawLocales = await fetcher({
    cacheId: `${PROVIDER_TYPE.i18n}-locales${segmentationValueCacheValue}`,
    fetchFn: () => i18nProvider.getLocales(segmentationValue)
  });
  return rawLocales.map(rawLocale => localeModel(rawLocale));
};

/**
 * Set the current locale of the application to the one of the provided code
 * It will request the locale info if needed
 * @param {String} localeCode ISO locale Code to use
 * @param {string} [segmentationValue] optional segment to get a different config
 *
 * @return {Promise<LocaleSimple>} A promise with the status of the operation including code and dir
 */
const setLocale = async (localeCode, segmentationValue) => {
  const segmentationValueCacheValue = getSegmentationCachePart(
    segmentationValue
  );
  const rawLocale = await fetcher({
    cacheId: `${PROVIDER_TYPE.i18n}-locale-${localeCode}${segmentationValueCacheValue}`,
    fetchFn: () => i18nProvider.getLocale(localeCode, segmentationValue)
  });
  const locale = dictionaryModel(localeCode, rawLocale);
  polyglot.setDictionary(locale.entries, localeCode);
  const currentLocale = {
    code: locale.code,
    dir: locale.dir,
    segmentationValue
  };
  settingsService.setLocale(currentLocale);
  return currentLocale;
};

/**
 * Initialize the internationalization Service, getting locales, dictionary and directionality
 * and setting the initial one
 * @param {string} [segmentationValue] optional segment to get a different config
 * @return {Promise<LocaleSimple>} a promise that will resolve when the i18n system is ready with the Locale(code and dir)
 */
const init = async segmentationValue => {
  const storedLocale = await settingsService.getLocale();
  const { code: localeCode, segmentationValue: storedSegmentationValue } =
    storedLocale || {};
  let updatedLocaledCode = localeCode;
  const segmentationValueCacheValue = getSegmentationCachePart(
    segmentationValue
  );
  // TODO: get System locale. Add it when XDK allows it
  if (!localeCode || storedSegmentationValue !== segmentationValue) {
    updatedLocaledCode = await fetcher({
      cacheId: `${PROVIDER_TYPE.i18n}-defaultlocale${segmentationValueCacheValue}`,
      fetchFn: () => i18nProvider.getDefaultLocaleCode(segmentationValue)
    });
  }
  const currentLocale = await setLocale(updatedLocaledCode, segmentationValue);
  return currentLocale;
};

/**
 * Gets the translation, with the interpolation done based on the options
 *
 * @param {String} key the key to get translation from
 * @param {any} options the options for the interpolation
 *
 * @return {String} the proper value for the provided key
 */
const getTranslation = (key, options = {}) => {
  return polyglot.get(key, options);
};

export { init, getLocale, getLocales, setLocale, getTranslation };
