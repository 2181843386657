let instance = null;

export default class CacheRequests {
  constructor() {
    if (!instance) {
      instance = new Map();
    }
    this.cache = instance;
  }

  set(url, response) {
    this.cache.set(url, response);
  }

  get(url) {
    return this.cache.get(url);
  }

  has(url) {
    return this.cache.has(url);
  }

  invalidate() {
    instance = new Map();
    this.cache = instance;
  }

  delete(url) {
    this.cache.delete(url);
  }
}
