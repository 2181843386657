import React, {
  useCallback,
  useContext,
  useState,
  useRef,
  useEffect
} from 'react';
import PropTypes from 'prop-types';

import { focusManager } from '@accedo/vdkweb-navigation';

import { AuthContext } from '#/context/AuthContext';
import { ProfileContext } from '#/context/ProfileContext';
import Page from '#/components/Page/Page';
import VerticalScroll from '#/components/VerticalScroll/VerticalScroll';
import VerticalLayout from '#/components/Layout/VerticalLayout';
import AssetBackground from '#/components/AssetBackground/AssetBackground';
import Container from '#/containers/Container/Container';
import {
  navIdMap,
  getIds,
  getIdOfTheFirstContainerAvailable
} from '#/utils/navigationHelper';
import useMenu from '#/hooks/useMenu';
import useBookmarks from '#/hooks/useBookmarks';
import usePage from '#/hooks/usePage';

import getResolution from '#/utils/getResolution';
import { R720p } from '#/theme/variables/breakpoints';
import { ACCEDO_CONTROL_CONTAINER_TEMPLATES } from '#/config/constants';
import useAppError from '#/hooks/useAppError';

const NON_SCROLABLE_ELEMENTS = ['elevate-filter-sort'];

const pageNav = ({ menuVisible: isMenuVisible, pageProps }) => ({
  id: pageProps.id || navIdMap.PAGE.CONTAINER.PAGE,
  nextup: isMenuVisible ? navIdMap.MENU.HEADER.CONTAINER : null,
  forwardFocus: navIdMap.PAGE.CONTAINER.LAYOUT
});

const layoutNav = ({ id: pageId }) => ({
  id: navIdMap.PAGE.CONTAINER.LAYOUT,
  parent: pageId
});

const ContainerPage = ({
  analyticsProps: providedAnalyticsProps,
  backgroundUrl,
  staticContainers = 0,
  containers = [],
  contextData,
  menuVisible = true,
  pageProps = {},
  defaultFocusIndex,
  scrollMargin: scrollMarginProp
}) => {
  const { width } = getResolution();
  const DEFAULT_CENTER_MARGIN = width > R720p ? -250 : -160;
  const scrollMargin = scrollMarginProp || DEFAULT_CENTER_MARGIN;
  const { showMenu, hideMenu } = useMenu({ menuVisible });
  const authContext = useContext(AuthContext);
  const [emptyContainers, setEmptyContainers] = useState([]);
  const [components, setComponents] = useState([]);
  const gridRef = useRef({});
  const verticalPositionRef = useRef({});
  const [currentGridState, setCurrentGridState] = useState({});
  const [nonScrollableContainers, setNonScrollableContainers] = useState({});
  const [verticalPosition, setVerticalPosition] = useState(0);
  const [navIds, setNavIds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isThereAnyGridContainer, setIsThereAnyGridContainer] = useState(false);
  const { currentProfile } = useContext(ProfileContext);
  const { addError } = useAppError();
  const { getPageLatestStateById } = usePage();
  const { userBookmarks } = useBookmarks({
    isAuthenticated: authContext.isAuthenticated,
    profileId: currentProfile?.id
  });

  const isJumpRecommendation = template => {
    return (
      template === ACCEDO_CONTROL_CONTAINER_TEMPLATES.carouselJumpRecommendation
    );
  };

  const isContinueWatching = template => {
    return template === ACCEDO_CONTROL_CONTAINER_TEMPLATES.continueWatching;
  };

  const isContainerValid = useCallback(
    container => {
      if (emptyContainers.includes(container.id)) {
        return false;
      }

      const { template } = container;

      if (isJumpRecommendation(template) && !authContext.isAuthenticated) {
        return false;
      }

      if (isContinueWatching(template) && userBookmarks) {
        container.items = userBookmarks;
      }

      return container.component || container.query || container.items?.length;
    },
    [authContext.isAuthenticated, emptyContainers, userBookmarks]
  );

  const filterContainers = useCallback(
    () => containers.filter(container => isContainerValid(container)),
    [containers, isContainerValid]
  );

  const onGridChange = useCallback(state => {
    setCurrentGridState({
      currentHead: state?.edge?.head,
      currentTail: state?.edge?.tail
    });
  }, []);

  const addEmptyContainers = useCallback(
    id => {
      setEmptyContainers(oldEmptyContainers => {
        if (!oldEmptyContainers.includes(id)) {
          return [...oldEmptyContainers, id];
        }
        return oldEmptyContainers;
      });
    },
    [setEmptyContainers]
  );

  const removeEmptyContainers = useCallback(
    id => {
      addEmptyContainers(id);
    },
    [addEmptyContainers]
  );

  const buildComponent = useCallback(
    (container, index) => {
      const containerProps = container.props || { ...container };
      const component = {
        Component: container.component || Container,
        skipNavigation: container.skipNavigation,
        props: {
          ...containerProps,
          contextData,
          index,
          nav: {
            id: container.id,
            ...containerProps.nav
          },
          moreItems: true,
          gridConfig: {
            useInternalArrows:
              !!isThereAnyGridContainer && containers.length > 1, // Only when there's more than one
            gridRef,
            onChange: onGridChange
          },
          removeEmptyContainers
        }
      };

      return component;
    },
    [
      containers.length,
      contextData,
      isThereAnyGridContainer,
      onGridChange,
      removeEmptyContainers
    ]
  );

  const buildComponents = useCallback(
    filteredContainers => {
      return filteredContainers.map((container, index) =>
        buildComponent(container, index)
      );
    },
    [buildComponent]
  );

  const isThereAnyGridIncluded = useCallback(
    filteredContainers =>
      filteredContainers.some(item => item.template?.includes('grid')),
    []
  );

  const getNonScrollableContainers = useCallback(
    filteredContainers =>
      filteredContainers.filter(component =>
        NON_SCROLABLE_ELEMENTS.includes(component?.props?.template)
      ),
    []
  );

  const buildNavIds = useCallback(
    filteredContainers => {
      if (!filteredContainers?.length) {
        return;
      }

      let containerIds = getIds(filteredContainers);

      if (menuVisible) {
        containerIds = [navIdMap.MENU.HEADER.CONTAINER, ...containerIds];
      }

      return containerIds;
    },
    [menuVisible]
  );

  const fallback = () => {
    setNavIds([]);
    setComponents([]);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!containers.length) {
      return fallback();
    }

    const filteredContainers = filterContainers();
    console.log(`[debug] filteredContainers: ${filteredContainers.length}`);

    if (!filteredContainers.length) {
      return addError();
    }

    const buildedComponents = buildComponents(filteredContainers);

    if (
      !buildedComponents.length ||
      buildedComponents.length <= staticContainers
    ) {
      return addError();
    }

    const buildedNavIds = buildNavIds(filteredContainers);
    const isThereAnyGrid = isThereAnyGridIncluded(filteredContainers);
    const nonScrollableContainersFiltered = getNonScrollableContainers(
      filteredContainers
    );

    setNavIds(buildedNavIds);
    setComponents(buildedComponents);
    setIsThereAnyGridContainer(isThereAnyGrid);
    setNonScrollableContainers(nonScrollableContainersFiltered);
    setIsLoading(false);
  }, [
    addError,
    staticContainers,
    buildComponents,
    buildNavIds,
    containers.length,
    filterContainers,
    emptyContainers,
    getNonScrollableContainers,
    isThereAnyGridIncluded
  ]);

  verticalPositionRef.current = verticalPosition;

  const pageTopFunction = useCallback(
    navId => {
      let margin = 0;
      const currentFocusContainer = document.getElementById(navId);

      if (
        containers.length !== 1 ||
        (nonScrollableContainers.length &&
          nonScrollableContainers[0].id !== navId)
      ) {
        margin = scrollMargin;
      }
      const top =
        currentFocusContainer && currentFocusContainer.offsetTop > 0
          ? -currentFocusContainer.offsetTop - margin
          : 0;

      setVerticalPosition(top);
      return {
        position: top
      };
    },
    [containers.length, nonScrollableContainers, scrollMargin]
  );

  const manualScrollUp = useCallback(() => {
    if (!isThereAnyGridContainer) {
      return false;
    }
    let handle = true;
    if (currentGridState.currentHead) {
      handle = false;
    } else {
      gridRef.current.page(-1);
    }
    return handle;
  }, [currentGridState.currentHead, isThereAnyGridContainer]);

  const manualScrollDown = React.useCallback(() => {
    if (!isThereAnyGridContainer) {
      return false;
    }
    let handle = true;
    if (parseInt(verticalPositionRef.current, 10) === 0) {
      handle = false;
    } else {
      gridRef.current.page(1);
    }
    return handle;
  }, [isThereAnyGridContainer]);

  const enableUpArrow = () => {
    if (isThereAnyGridContainer) {
      return !currentGridState.currentHead || verticalPosition !== 0;
    }
    return undefined;
  };

  const enableDownArrow = () => {
    if (isThereAnyGridContainer) {
      return !currentGridState.currentTail;
    }
    return undefined;
  };

  const onForwardDidMount = useCallback(() => {
    focusManager.changeFocus(pageNav({ menuVisible, pageProps }).id);
    pageProps.onForwardDidMount && pageProps.onForwardDidMount();
  }, [menuVisible, pageProps]);

  const onBackwardDidMount = useCallback(
    latestState => {
      pageProps.onBackwardDidMount && pageProps.onBackwardDidMount(latestState);
    },
    [pageProps]
  );

  const onForwardWillUnmount = useCallback(
    id => {
      pageProps?.onForwardWillUnmount?.(id);
    },
    [pageProps]
  );

  const onBackwardWillUnmount = useCallback(
    id => {
      pageProps?.onBackwardWillUnmount?.(id);
    },
    [pageProps]
  );

  // pageConfig
  const pageNavObject = pageNav({ menuVisible, pageProps });
  const saveFocusedElementOnUnmount =
    pageProps.saveFocusedElementOnUnmount !== undefined
      ? pageProps.saveFocusedElementOnUnmount
      : true;
  const restoreLastFocusedElementOnMount =
    pageProps.restoreLastFocusedElementOnMount !== undefined
      ? pageProps.restoreLastFocusedElementOnMount
      : true;

  useEffect(() => {
    const currentPageState = getPageLatestStateById(pageNavObject.id);

    if (emptyContainers?.length > 0 && !currentPageState?.focusedElement) {
      const idOfTheFirstContainerAvailable = getIdOfTheFirstContainerAvailable(
        containers,
        emptyContainers,
        authContext,
        userBookmarks,
        ACCEDO_CONTROL_CONTAINER_TEMPLATES.continueWatching
      );

      if (!idOfTheFirstContainerAvailable) {
        return;
      }

      focusManager.changeFocus(idOfTheFirstContainerAvailable);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emptyContainers, authContext, userBookmarks, pageNavObject.id]);

  return (
    <>
      {backgroundUrl && <AssetBackground backgroundUrl={backgroundUrl} />}
      {!isLoading && (
        <VerticalScroll
          parent={pageNavObject.id}
          pageTopFunction={pageTopFunction}
          navIds={navIds}
          hideMenu={hideMenu}
          menuVisible={menuVisible}
          showMenu={showMenu}
          manualScrollDown={manualScrollDown}
          manualScrollUp={manualScrollUp}
          enableDownArrow={enableDownArrow()}
          enableUpArrow={enableUpArrow()}
        >
          <Page
            className={pageProps.className}
            id={pageNavObject.id}
            analyticsProps={providedAnalyticsProps}
            nav={pageNavObject}
            onBackwardDidMount={onBackwardDidMount}
            onForwardDidMount={onForwardDidMount}
            onForwardWillUnmount={onForwardWillUnmount}
            onBackwardWillUnmount={onBackwardWillUnmount}
            saveFocusedElementOnUnmount={saveFocusedElementOnUnmount}
            restoreLastFocusedElementOnMount={restoreLastFocusedElementOnMount}
          >
            <VerticalLayout
              components={components}
              defaultFocusIndex={defaultFocusIndex}
              nav={layoutNav(pageProps)}
            />
          </Page>
        </VerticalScroll>
      )}
    </>
  );
};

ContainerPage.propTypes = {
  analyticsProps: PropTypes.shape({
    contentId: PropTypes.string,
    description: PropTypes.string,
    duration: PropTypes.string,
    episodeNumber: PropTypes.number,
    publishDate: PropTypes.string,
    genre: PropTypes.string,
    seasonNumber: PropTypes.number,
    title: PropTypes.string
  }),
  staticContainers: PropTypes.number,
  backgroundUrl: PropTypes.string,
  contextData: PropTypes.object,
  containers: PropTypes.arrayOf(
    PropTypes.shape({
      items: PropTypes.array,
      id: PropTypes.string,
      template: PropTypes.string,
      query: PropTypes.string,
      containerNavIds: PropTypes.arrayOf(PropTypes.string),
      props: PropTypes.object
    })
  ),
  defaultFocusIndex: PropTypes.number,
  menuVisible: PropTypes.bool,
  pageProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    restoreLastFocusedElementOnMount: PropTypes.bool,
    saveFocusedElementOnUnmount: PropTypes.bool,
    onBackwardDidMount: PropTypes.func,
    onForwardDidMount: PropTypes.func,
    onForwardWillUnmount: PropTypes.func,
    onBackwardWillUnmount: PropTypes.func
  }).isRequired,
  scrollMargin: PropTypes.number
};

export default ContainerPage;
