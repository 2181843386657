/**
 * @module services/routes
 * @description
 * Service to handle the fetch of Routes to do the mapping
 */
import cms from '#/providers/cms';
import routeModel from '#/models/route';
import { PROVIDER_TYPE } from '#/config/constants';

import fetcher from '../../helpers/fetcher';

/**
 * Gets the Routes of the application, which are all the non-menu related routes the application needs to add to its router
 *
 * @param {string} [segmentationValue] the value for the segmentation
 *
 * @returns {Promise<any[]>} an array with all the routes
 */
const getRoutes = async segmentationValue => {
  const routes = [];
  const routesResponse = await fetcher({
    cacheId: `${PROVIDER_TYPE.cms}${segmentationValue || ''}-routeMapping`,
    fetchFn: () => cms.getRoutes(segmentationValue)
  });
  routesResponse?.forEach(route => {
    routes.push(routeModel(route));
  });

  return routes;
};

export { getRoutes };
