import ovp from '#/providers/ovp';
import Movie from '#/models/movie/movie';
import Show from '#/models/show/show';
import { PROVIDER_TYPE } from '#/config/constants';
import { getSegmentationCachePart } from '#/utils/segmentationUtils';
import fetcher from '../../helpers/fetcher';

/**
 * @module services/search
 * @description
 * Service to handle the search methods
 */

/**
 * Sort two elements to determine the best order
 * @param {Object} a Array element
 * @param {Object} b Array element
 * @returns {Number} Sort result
 */
const sort = (a, b) => {
  if (a.title < b.title) {
    return -1;
  }
  if (a.title > b.title) {
    return 1;
  }
  return 0;
};

/**
 * Search for Movies and Shows
 * @param {Object} params Params object
 * @param {String} params.keyword Keyword to search
 * @param {Number} [params.amount] Items per type
 * @param {string} [params.segmentationValue] optional segment to get a different value based on config
 * @returns {Promise<Object[]>} Results
 */
const search = async ({ keyword, amount = 5, segmentationValue } = {}) => {
  const segmentationValueCacheValue = getSegmentationCachePart(
    segmentationValue
  );
  const rawMovies = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-search-movies-${keyword}${segmentationValueCacheValue}`,
    fetchFn: () => ovp.searchMovies({ keyword, amount, segmentationValue })
  });

  const rawShows = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-search-shows-${keyword}${segmentationValueCacheValue}`,
    fetchFn: () => ovp.searchShows({ keyword, amount, segmentationValue })
  });

  const movies = rawMovies.entries.map(movie => Movie(movie));
  const shows = rawShows.entries.map(show => Show(show));

  return {
    count: movies.length + shows.length,
    movies,
    shows
  };
};

/**
 * Get last publised movies and shows to represent as trends
 * @param {Object} params Params object
 * @param {Number} [params.amount] Items per type, by default is 5
 * @param {string} [segmentationValue] optional segment to get a different value based on config
 * @returns {Promise<Object[]>} Results
 */
const getTrends = async ({ amount = 5, segmentationValue } = {}) => {
  const segmentationValueCacheValue = getSegmentationCachePart(
    segmentationValue
  );
  const options = {
    pageSize: amount,
    sortBy: '-publishedDate',
    segmentationValue
  };
  const rawMovies = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-search-movies${segmentationValueCacheValue}`,
    fetchFn: () => ovp.getMovies(options)
  });

  const rawShows = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-search-shows${segmentationValueCacheValue}`,
    fetchFn: () => ovp.getTvShows(options)
  });

  const movies = rawMovies.entries.map(movie => Movie(movie));
  const shows = rawShows.entries.map(show => Show(show));

  const results = movies.concat(shows);
  return results.sort(sort);
};

export { search, getTrends };
