import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { FocusButton, FocusDiv } from '@accedo/vdkweb-tv-ui';
import { vKey } from '@accedo/xdk-virtual-key';

import useKeyHandler from '#/hooks/useKeyHandler';
import useI18n from '#/hooks/useI18n';
import { navIdMap } from '#/utils/navigationHelper';
import isRTL from '#/utils/isRTL';
import { getTranslation } from '#/services/i18n';

import styles from './popup.scss';

const NAV_IDS = navIdMap.POPUP;

/**
 * Global Popup Component. With title, content and up to 2 buttons for Accept and Cancel
 *
 * @param {Object} props The object properties
 * @param {Object} props.nav The navigation object
 * @param {Object} props.theme The CSS theme for the component
 * @returns {React.ReactComponentElement} Component
 */
const Popup = ({
  nav,
  theme,
  acceptText,
  cancelText,
  onAccept,
  onCancel,
  isOpen = true,
  isAppInitError,
  ...props
}) => {
  const { currentLocale = {} } = useI18n();
  const { dir } = currentLocale;
  const rtl = isRTL(dir);
  const containerStyle = theme?.container || styles.container;
  const innerStyle = theme?.inner || styles.inner;
  const isAcceptVisible = !!onAccept;
  const isCancelVisible = !!onCancel;

  const navAcceptBtnId = `${nav.id}_${NAV_IDS.OK_POPUP}`;
  const navCancelBtnId = `${nav.id}_${NAV_IDS.CANCEL_POPUP}`;

  const acceptBtnText = acceptText || getTranslation('okButton').toUpperCase();
  const cancelBtnText =
    cancelText || getTranslation('cancelButton').toUpperCase();

  const keyHandler = useCallback(
    ({ id }, event) => {
      const { BACK, OK } = vKey;

      if (id === BACK.id && isOpen) {
        event.stopImmediatePropagation();
        if (onCancel) {
          onCancel();
        }
      } else if (id === OK.id && isAppInitError) {
        if (onAccept) {
          onAccept();
        }
      }
    },
    [isOpen, isAppInitError, onCancel, onAccept]
  );

  useKeyHandler(keyHandler);

  console.log(theme);

  return (
    <FocusDiv
      className={theme?.errorPopupContainer || containerStyle}
      nav={{
        id: nav.id,
        parent: nav.parent,
        forwardFocus: onAccept ? navAcceptBtnId : navCancelBtnId
      }}
      style={{ display: isOpen ? 'block' : 'none' }}
    >
      <div className={theme?.errorPopupInner || innerStyle} dir={dir}>
        <div className={theme?.title || styles.title} dir={dir}>
          <b>{props.title}</b>
        </div>
        <div className={theme?.content || styles.content} dir={dir}>
          {props.content}
        </div>
        <div className={styles.buttonsContainer} dir={dir}>
          <FocusButton
            nav={{
              id: navAcceptBtnId,
              parent: nav.id,
              nextup: nav?.nextup || navAcceptBtnId,
              nextdown: nav?.nextdown || navAcceptBtnId,
              nextleft:
                rtl && isCancelVisible ? navCancelBtnId : navAcceptBtnId,
              nextright:
                !rtl && isCancelVisible ? navCancelBtnId : navAcceptBtnId
            }}
            theme={{
              buttonContainer:
                theme?.focusButtonContainer || styles.focusButtonContainer,
              button: theme?.focusButton || styles.focusButton,
              buttonFocused:
                theme?.focusButtonFocused || styles.focusButtonFocused,
              buttonActive: theme?.focusButtonActive || styles.focusButtonActive
            }}
            style={{ display: isAcceptVisible ? 'block' : 'none' }}
            onClick={onAccept}
          >
            {acceptBtnText}
          </FocusButton>
          <FocusButton
            nav={{
              id: navCancelBtnId,
              parent: nav.id,
              nextup: nav?.nextup || navCancelBtnId,
              nextdown: nav?.nextdown || navCancelBtnId,
              [!rtl ? 'nextleft' : 'nextright']: onAccept
                ? navAcceptBtnId
                : navCancelBtnId
            }}
            theme={{
              buttonContainer:
                theme?.focusButtonContainer || styles.focusButtonContainer,
              button: theme?.focusButton || styles.focusButton,
              buttonFocused:
                theme?.focusButtonFocused || styles.focusButtonFocused,
              buttonActive: theme?.focusButtonActive || styles.focusButtonActive
            }}
            style={{ display: isCancelVisible ? 'block' : 'none' }}
            onClick={onCancel}
          >
            {cancelBtnText}
          </FocusButton>
        </div>
      </div>
    </FocusDiv>
  );
};

Popup.propTypes = {
  /** nav object from @accedo/vdkweb-navigation */
  nav: PropTypes.object,
  /** Title of the Popup, a React object is allowed so it can be styled */
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /** Content of the Popup, a React object is allowed so it can be styled */
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /** Function to execute when Accept button is actioned */
  onAccept: PropTypes.func,
  /** Function to execute when Cancel button is actioned. If it's not present, there will be no Cancel Button */
  onCancel: PropTypes.func,
  /** whether the popup is opened or not */
  isOpen: PropTypes.bool,
  /** whether the popup is opened in app init or not */
  isAppInitError: PropTypes.bool,
  /** CSS module theme */
  theme: PropTypes.object,
  /** Text for the Accept button */
  acceptText: PropTypes.string,
  /** Text for the Cancel button if onCancel is included */
  cancelText: PropTypes.string
};

export default Popup;
