import { useCallback, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { HistoryContext, HistorySetContext } from '#/context/AppHistoryContext';
import { ROUTES } from '#/config/constants';

const useHistoryBack = () => {
  const navigate = useNavigate();
  const historyContext = useContext(HistoryContext);
  const setContext = useContext(HistorySetContext);
  const { pathname } = useLocation();
  if (historyContext === undefined || setContext === undefined) {
    throw new Error('useHistoryBack must be used within a HistoryProvider');
  }
  return useCallback(() => {
    const { stack } = historyContext;
    let nextRoute;
    let i = 1;
    // now we need to count how many step back we should make

    if (stack.length) {
      nextRoute = stack[stack.length - 1];
      while (nextRoute.skipCurrentOnBack || nextRoute.path === pathname) {
        i += 1;
        stack.pop(); // pop next route due to is skip
        nextRoute = stack.length
          ? stack[stack.length - 1]
          : { path: ROUTES.home };
      }
    }
    stack.pop();
    setContext({
      ...historyContext,
      stack
    });
    navigate(-i);
  }, [navigate, setContext, historyContext, pathname]);
};

export default useHistoryBack;
