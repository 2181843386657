import { getOVPConfig } from '#/providers/shared/control/config';

const DEFAULT_PAGE_SIZE = 15;

const recalculateRange = ({
  to,
  from,
  pageSize,
  itemsLength,
  pageNumberTo,
  pageNumberFrom
}) => {
  let sliceTo = to;
  let sliceFrom = from;

  if (itemsLength <= pageSize) {
    if (to > pageSize) {
      // [52-59] rango
      sliceTo = to - pageSize * (pageNumberTo - 1);
      sliceFrom = from - pageSize * (pageNumberFrom - 1) - 1;
    }
  } else if (to > pageSize * 2) {
    sliceTo = to - pageSize * (pageNumberTo - 2);
    sliceFrom = from - pageSize * (pageNumberFrom - 1) - 1;
  }

  return { newFrom: sliceFrom, newTo: sliceTo };
};

const getDefaultParams = async segmentationValue => {
  // OVP API url is built based on the CMS Config value if any or the value from the static config if not provided
  const ovpConfiguration = await getOVPConfig(segmentationValue);
  return { pageSize: ovpConfiguration?.pageSize || DEFAULT_PAGE_SIZE };
};

/**
 * creates the opts object to pass to the OVP API, extending with the default params
 * @param {object} opts params object
 * @returns {Promise<object>} the opts param to pass to the OVP API
 */
const createOptsObject = async opts => {
  const finalOptions = {};
  const defaultParams = await getDefaultParams(opts.segmentationValue);
  Object.keys(opts).forEach(key => {
    const value = opts[key];
    if (value !== undefined && value !== null) {
      finalOptions[key] = value;
    } else {
      finalOptions[key] = defaultParams[key] || undefined;
    }
  });
  const toReturnValue = {
    ...defaultParams,
    ...finalOptions
  };
  return toReturnValue;
};

export { recalculateRange, createOptsObject, getDefaultParams };
