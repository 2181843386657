import idp from '#/providers/idp';
import { Profile } from '#/interfaces/Profile';
import { Avatar } from '#/interfaces/Avatar';
import ProfileModel from '#/models/profile/profile';
import AvatarModel from '#/models/avatar/avatar';

const getProfiles = async (): Promise<Profile[]> => {
  const rawProfiles = await idp.getProfiles();

  const profileModel = new ProfileModel();

  const profiles = rawProfiles.map(rawProfile => {
    return profileModel.fromRawData(rawProfile).create();
  });
  return profiles;
};

const getProfile = async (id: string | number): Promise<Profile> => {
  const { profile: rawProfile } = await idp.getProfile(id);
  const profileModel = new ProfileModel();
  return profileModel.fromRawData(rawProfile).create();
};

const createProfile = async (profile: Profile): Promise<any> => {
  const profileModel = new ProfileModel(profile);
  const rawData = profileModel.toRawData();
  return idp.createProfile(rawData);
};

const deleteProfile = async (id: string | number): Promise<any> => {
  return idp.deleteProfile(id);
};

const updateProfileName = async (
  id: string | number,
  name: string
): Promise<any> => {
  return idp.updateProfileName(id, name);
};

const updateProfileAvatar = async (
  id: string | number,
  avatar: string
): Promise<any> => {
  return idp.updateProfileAvatar(id, avatar);
};

const getAvatars = async (): Promise<Avatar[]> => {
  const rawAvatars = await idp.getAvatars();

  const avatarModel = new AvatarModel();

  const avatars = rawAvatars.map(rawProfile => {
    return avatarModel.fromRawData(rawProfile).create();
  });

  return avatars;
};

const createPIN = async (
  profileId: string | number,
  pin: string
): Promise<any> => {
  return idp.createPIN(profileId, pin);
};

const updatePIN = async (
  profileId: string | number,
  pin: string
): Promise<any> => {
  return idp.updatePIN(profileId, pin);
};

const deletePIN = async (profileId: string | number): Promise<any> => {
  return idp.deletePIN(profileId);
};

export {
  getProfile,
  getProfiles,
  deleteProfile,
  updateProfileName,
  updateProfileAvatar,
  createProfile,
  getAvatars,
  createPIN,
  updatePIN,
  deletePIN
};
