import React, { useRef } from 'react';
import useI18n from '#/hooks/useI18n';
import SnackBar from '#/components/SnackBar/SnackBar';
import useNotificationSystem from '#/hooks/useNotificationSystem';

import {
  NOTIFICATION_POSITIONS,
  NOTIFICATION_TYPES,
  NOTIFICATION_GROW_VALUES,
  NotificationExtendedType
} from '#/interfaces/Notification';

import theme from './notificationStack.scss';

export interface NotificationStackProps {
  notifications: NotificationExtendedType[];
  position?: NOTIFICATION_POSITIONS;
  grow?: NOTIFICATION_GROW_VALUES;
}

const NotificationStack = ({
  notifications,
  position = NOTIFICATION_POSITIONS.bottomend,
  grow = NOTIFICATION_GROW_VALUES.down
}: NotificationStackProps) => {
  const { hideNotification } = useNotificationSystem();
  const { currentLocale = {} } = useI18n();
  const timeouts = useRef<any>({});
  const { dir = 'ltr' } = currentLocale;
  return (
    <div
      className={`${theme.notificationGroup} ${theme[position]} ${theme[grow]} ${theme[dir]}`}
    >
      {notifications?.map(notification => {
        const { timer, id } = notification;
        if (!!timer && timer !== 0) {
          if (!timeouts.current[id]) {
            clearTimeout(timeouts.current[id]);
            timeouts.current[id] = null;
          }
          timeouts.current[id] = setTimeout(() => {
            hideNotification?.(id);
          }, timer * 1000);
        }
        return (
          <div
            className={theme.notification}
            key={`${notification.id}-container`}
          >
            <SnackBar
              id={id}
              key={notification.id}
              isNew={notification.isNew}
              toHide={notification.toHide}
              description={notification.description}
              title={notification.title}
              type={notification.type}
              dir={dir}
              image={notification.image}
            />
          </div>
        );
      })}
    </div>
  );
};

export default NotificationStack;
export { NOTIFICATION_TYPES, NOTIFICATION_POSITIONS };
