import { useContext, useCallback, useMemo } from 'react';
import { addClass, removeClass } from '#/utils/styleClasses';
import {
  ExtraClassesContext,
  ExtraClassesSetContext
} from '#/context/ExtraStyleClassesProvider';

const useExtraStyleClasses = () => {
  const extraClassesContext = useContext(ExtraClassesContext);
  const setExtraClassesContext = useContext(ExtraClassesSetContext);

  const addExtraClass = useCallback(
    className => {
      const extraClasses = extraClassesContext;
      const updateExtraClass = setExtraClassesContext;
      const newExtraClasses = addClass({
        classes: extraClasses,
        newClass: className
      });
      updateExtraClass?.([...newExtraClasses]);
    },
    [extraClassesContext, setExtraClassesContext]
  );

  const removeExtraClass = useCallback(
    className => {
      const extraClasses = extraClassesContext;
      const updateExtraClass = setExtraClassesContext;
      const newClasses = removeClass({
        classes: extraClasses,
        classToRemove: className
      });
      updateExtraClass?.(newClasses);
    },
    [extraClassesContext, setExtraClassesContext]
  );

  const extraClassesMemo = useMemo(() => {
    const extraClasses = extraClassesContext;
    return extraClasses;
  }, [extraClassesContext]);

  return { extraClasses: extraClassesMemo, addExtraClass, removeExtraClass };
};

export default useExtraStyleClasses;
