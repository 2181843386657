/**
 * @module services/theme
 * @description
 * Service to handle the fetch of theme file from the CMS.
 * @return {VikiElevateTheme} The modelised theme file.
 */
import cms from '#/providers/cms';
import themeModel from '#/models/theme';
import { PROVIDER_TYPE } from '#/config/constants';
import { getSegmentationCachePart } from '#/utils/segmentationUtils';
import fetcher from '../../helpers/fetcher';

const getDefaultTheme = async segmentationValue => {
  const segmentationCachePart = getSegmentationCachePart(segmentationValue);
  const cacheId = `${PROVIDER_TYPE.cms}-theme${segmentationCachePart}`;
  const theme = await fetcher({
    cacheId,
    fetchFn: () => cms.getDefaultTheme(segmentationValue)
  });
  return theme && themeModel(theme);
};

const getThemeByPage = async ({ route, locale, segmentationValue }) => {
  const localeCachePart = locale ? `-${locale}` : '';
  const segmentationCachePart = getSegmentationCachePart(segmentationValue);
  const cacheId = `${PROVIDER_TYPE.cms}-${route}-theme${localeCachePart}${segmentationCachePart}`;
  const defaultTheme = await getDefaultTheme(segmentationValue);
  const theme = await fetcher({
    cacheId,
    fetchFn: () =>
      cms.getThemeByPage({
        route,
        locale,
        gid: segmentationValue
      })
  });
  return theme ? themeModel(theme) : defaultTheme;
};

export { getThemeByPage, getDefaultTheme };
